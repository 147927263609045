import React, { useEffect, useState } from "react";
import "./rdcalculator.css"
import { Tooltip as ReactTooltip } from "react-tooltip";
import 'react-tooltip/dist/react-tooltip.css'
import Slider from 'react-rangeslider';
import 'react-rangeslider/lib/index.css';
import Chart from 'react-apexcharts'
import { useNavigate } from "react-router-dom";
import Tabs from "../../components/tabs/tabs";

export default function RDCalculator() {
  const navigate = useNavigate()
  const [investValue, setInvestValue] = React.useState(10000);
  const [rate, setRate] = React.useState(3);
  const [time, setTime] = React.useState(5);
  const [investFlag, setInvestFlag] = React.useState(false);
  const [investErr, setInvestErr] = React.useState("");
  const [timetFlag, setTimeFlag] = React.useState(false);
  const [timetErr, setTimeErr] = React.useState("");
  const [ratetFlag, setRateFlag] = React.useState(false);
  const [ratetErr, setRateErr] = React.useState("");
  const [invsVal, setEnvVal] = React.useState("");
  const [returnVal, setReturnVal] = React.useState("");
  const [totalVal, setTotalVal] = React.useState("");
  const [days, setDaays] = React.useState("years");
  const [data, setData] = useState({
    options: {
      chart: {
        type: 'donut',
      },
      tooltip: {
        y: {
          title: {
            formatter(seriesName) {
              return seriesName
            }
          }
        }
      },
      labels: [],
      dataLabels: {
        dropShadow: {
          blur: 3,
          opacity: 0.8,
        }
      },
      colors: ['#0073BB', '#E05776'],
    },
    series: [],

  })

  const handleChangeComplete = () => {
  }


  useEffect(() => {
    if (days == 'years') {
      if (time < 1) {
        setTimeFlag(true);
        setTimeErr("Minimum value allowed is 1")
      } else if (time > 10) {
        setTimeFlag(true);
        setTimeErr("Maximun value allowed is 10")
      } else {
        setTimeFlag(false);
        setTimeErr("")
      }
    } else {
      if (time < 3) {
        setTimeFlag(true);
        setTimeErr("Minimum value allowed is 3")
      } else if (time > 120) {
        setTimeFlag(true);
        setTimeErr("Maximun value allowed is 120")
      } else {
        setTimeFlag(false);
        setTimeErr("")
      }
    }

  }, [time]);

  useEffect(() => {
    if (investValue < 500) {
      setInvestFlag(true);
      setInvestErr("Minimum value allowed is 500")
    } else if (investValue > 10000000) {
      setInvestFlag(true);
      setInvestErr("Maximun value allowed is 10000000")
    } else {
      setInvestFlag(false);
      setInvestErr("")
    }
  }, [investValue]);

  useEffect(() => {
    if (rate < 1) {
      setRateFlag(true);
      setRateErr("Minimum value allowed is 1")
    } else if (rate > 15) {
      setRateFlag(true);
      setRateErr("Maximun value allowed is 15")
    } else {
      setRateFlag(false);
      setRateErr("")
    }
  }, [rate]);


  useEffect(() => {
    if (days === "years" && time > 10) {
      setTime(10)
    }
  }, [days]);

  const handleCalculation = (investment, rate, time) => {
    if (parseInt(investment) > 499 && parseInt(rate) >= 1 && parseInt(time) >= 0) {
      const p = investment;
      const i = rate;
      let n = time;
      if (days == 'years') {
        n = time * 12
      } else {
        n = time
      }

      const Investedamount = p * n;
      const e = Math.pow((1 + i / 400), n / 3);
      const d = Math.pow((1 + i / 400), (-1 / 3));
      const m = (p * (e - 1)) / (1 - d);
      const Interest = m - Investedamount;
      let nf = new Intl.NumberFormat('en-IN');
      setEnvVal(nf.format(Investedamount.toFixed(0)));
      setReturnVal(nf.format(Interest.toFixed(0)));
      setTotalVal(nf.format(m.toFixed(0)));
      let dt = data;
      dt.options.labels[0] = "Total Investment(₹) : " + " " + nf.format(Investedamount);
      dt.options.labels[1] = "Total Returns(₹) : " + " " + nf.format(Interest?.toFixed(0));
      dt.series[0] = parseInt(Investedamount.toFixed(0))
      dt.series[1] = parseInt(Interest.toFixed(0))
      setData(dt);
    }
    else{
      let nf = new Intl.NumberFormat('en-IN');
      setEnvVal(0);
      setReturnVal(0);
      setTotalVal(0);
      
      let dt = data;
      dt.options.labels[0] = "Total Investment(₹) : " + " " + '₹' + " " + nf.format(0);
      dt.options.labels[1] = "Total Returns(₹) : " + " " + '₹' + " " + nf.format(0);
      dt.series[0] = 50
      dt.series[1] = 50
      setData(dt);
  }

  }

  useEffect(() => {
    handleCalculation(investValue, rate, time);
  }, [investValue, rate, time, days]);

  function Example(props) {
    return (
      <Chart className='donut-chart' options={data.options} series={data.series} type="donut" />
    );
  }

  return (
    <div>
      <Tabs/>
    <div className="custome-row">
    <div className="container mt-5 pt-5">
      <div className='path-text mt-4' style={{ marginLeft: 14 }} > <span className="ta-cursor" onClick={() => {
        navigate("/")
      }}>Calculator</span> &gt; Recurring Deposit Calculator</div>
      <div className='mt-0 fd-cal-text mt-4' style={{ marginLeft: 14 }}>Recurring Deposit Calculator</div>
      <div className='row group-background ' style={{ marginLeft: 10 }}>
        <div className='col-lg-8 col-12 mt-4'>
          <div className='background p-4' id="content-sip">
            <div>
              <div className='d-flex justify-content-between'>
                <div>Monthly Investment</div>
                <div className="d-flex" style={{ width: 115 }}>
                  <span>
                    {investFlag === true ? <svg data-tooltip-id="invest" style={{ color: '#E05776' }} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" height="18" width="18" class="cis93InfoIcon contentNegative"><path fill="none" d="M0 0h24v24H0z"></path><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-6h2v6zm0-8h-2V7h2v2z"></path></svg> : ""}
                  </span>&nbsp;
                  <ReactTooltip id="invest" place="top" content={investErr} />
                  <input style={{ background: investFlag === true ? "rgba(187, 3, 0, 0.1)" : 'rgba(0, 115, 187, 0.10)', color: investFlag === true ? "#E05776" : '' }} className={`form-control shadow-none sm-fl`} id={`${investFlag === true ? "fname1" : "fname"}`} value={"₹"} readOnly />
                  <input onChange={e => {
                    if (isNaN(e.target.value)) { } else if (e.target.value.includes("+") == true) { } else if (e.target.value.includes("-") == true) { } else if (e.target.value.includes(".") == true) { } else {
                      if (e.target.value > 10000000) { setInvestValue(10000000); } else if (e.target.value == "") { setInvestValue(0); } else if (e.target.value == 0) { setInvestValue(0); } else {
                        if (e.target.value.indexOf(0) == '0' && e.target.value.length > 1) {
                          let va = e.target.value.substring(1)
                          setInvestValue(va)
                        } else if (investValue == 0 && e.target.value?.split("")[1] == '0') {
                          let va = e.target.value?.split("")[0];
                          setInvestValue(va)
                        } else {
                          setInvestValue(e.target.value)
                        }
                      }
                    }
                  }} value={investValue} maxLength={10000000} style={{ background: investFlag === true ? "rgba(187, 3, 0, 0.1)" : 'rgba(0, 115, 187, 0.10)', color: investFlag === true ? "#E05776" : '', paddingLeft: 0, textAlign: "right" }} className={`shadow-none form-control sm-bg rd-max`} type="text" id={`${investFlag === true ? "fname1" : "fname"}`} name="fname" />
                </div>
              </div>
              <Slider
                min={100}
                max={10000000}
                value={investValue}
                onChange={(value) => {
                  setInvestValue(value)
                }}
                onChangeComplete={handleChangeComplete}
              />
            </div>
            <div>
              <div className='d-flex justify-content-between '>
                <div>Rate Of Interest (P.A)</div>
                <div className="d-flex" style={{ width: 115 }}>
                  <span >
                    {ratetFlag === true ? <svg data-tooltip-id="time" style={{ color: '#E05776' }} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" height="18" width="18" class="cis93InfoIcon contentNegative"><path fill="none" d="M0 0h24v24H0z"></path><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-6h2v6zm0-8h-2V7h2v2z"></path></svg> : ""}
                  </span>&nbsp;
                  <ReactTooltip id="time" place="top" content={ratetErr} />

                  <input onChange={e => {
                    debugger
                    if (isNaN(e.target.value)) { } else if (e.target.value.includes("+") == true) { } else if (e.target.value.includes("-") == true) { } else {
                      if (rate != 0 && e.target.value > 15) { setRate(15) } else if (e.target.value === "") {
                        setRate(0)
                      } else {
                        if (e.target.value.includes(".") == true && e.target.value?.split("")[1] == "0" && e.target.value?.split("")[0] != 1) { setRate(0) } else if (e.target.value.indexOf(0) == '0' && e.target.value >= 1) {
                          let va = e.target.value.substring(1)
                          setRate(va)
                        } else if (rate == 0 && e.target.value?.split("")[1] == '0') {

                          let va = e.target.value?.split("")[0];
                          setRate(va)
                        } else {
                          if (e.target.value.includes(".") == true && e.target.value >= 1) {
                            let srr = e.target.value.split(".");
                            if (srr[1]?.length > 2) {
                            } else {
                              let str = (e?.target?.value)
                              setRate(str)
                            }
                          } else if (e.target.value >= 1) {
                            let str = e?.target?.value
                            setRate(str)
                          }
                        }
                      }
                    }

                  }} value={rate} className='shadow-none form-control sm-bgr' style={{ color: ratetFlag === true ? "#E05776" : '', paddingLeft: 0, textAlign: "right" }} type="text" id={`${ratetFlag === true ? "fname1" : "fname"}`} name="fname" />
                  <span style={{ background: ratetFlag === true ? "rgba(187, 3, 0, 0.1)" : 'rgba(0, 115, 187, 0.10)', color: ratetFlag === true ? "#E05776" : '', marginLeft: 2, paddingLeft: 0, paddingRight: 2 }} className='shadow-none form-control sm-fr wdthcls' id={`${ratetFlag === true ? "fname1" : "fname"}`}>%</span>
                </div>
              </div>
              <Slider
                min={1}
                max={15}
                value={rate}
                onChange={(value) => {
                  setRate(value.toFixed(2))
                }}
                onChangeComplete={handleChangeComplete}
                step={0.1}
              />
              <div className='d-flex justify-content-between'>
                <div className="row" style={{ width: "34%" }}>
                  <div className="col-md-6">
                    Time Period
                  </div>
                  <div className="col-md-6">
                    <select className="form-control shadow-none" onChange={(e) => {
                      setDaays(e.target.value);
                    }}>
                      <option value="years">Years</option>
                      <option value="months">Months</option>
                    </select>
                  </div>


                </div>
                <div className="d-flex" style={{ width: 115 }}>
                  <span>
                    {timetFlag === true ? <svg data-tooltip-id="rate" style={{ color: '#E05776' }} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" height="18" width="18" class="cis93InfoIcon contentNegative"><path fill="none" d="M0 0h24v24H0z"></path><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-6h2v6zm0-8h-2V7h2v2z"></path></svg> : ""}
                  </span>&nbsp;
                  <ReactTooltip id="rate" place="top" content={timetErr} />
                  <div className="d-flex">
                    <input onChange={e => {
                      if (isNaN(e.target.value)) { } else if (e.target.value.includes("+") == true) { } else if (e.target.value.includes("-") == true) { } else if (e.target.value.includes(".") == true) { } else {
                        if (days == "years") {
                          if (time != 0 && e.target.value > 10) { setTime(10) } else if (e.target.value === "") {
                            setTime(parseFloat(0))
                          } else {
                            if (time == 0 && e.target.value?.split("")[1] == '0') {
                              let va = e.target.value?.split("")[0];
                              setTime(va)
                            } else {
                              let str = Number(e.target.value).toString();
                              setTime(str)
                            }
                          }
                        } else {
                          if (time != 0 && e.target.value > 120) { setTime(120) } else if (e.target.value === "") {
                            setTime(parseFloat(0))
                          } else {
                            if (time == 0 && e.target.value?.split("")[1] == '0') {
                              let va = e.target.value?.split("")[0];
                              setTime(va)
                            } else {
                              let str = Number(e.target.value).toString();
                              setTime(str)
                            }
                          }
                        }
                      }

                    }} value={time} className='shadow-none form-control' style={{ color: timetFlag === true ? "#E05776" : '', textAlign: "right", marginLeft: 10, height: 32 }} type="text" id={`${timetFlag === true ? "fname1" : "fname"}`} name="fname" />
                  </div>
                </div>
              </div>

            </div>
            <div>
              <Slider
                min={1}
                max={days === 'years' ? 10 : 120}
                value={time}
                onChange={(value) => {
                  setTime(value)
                }}
                onChangeComplete={handleChangeComplete}
              />
            </div>
            <div className='d-flex justify-content-between pb-4'>
              <div className='sip-count-text mt-4'><b>Invested Amount</b></div>
              <div className="mt-4"><b>₹ {invsVal}</b></div>
            </div>
            <div className='d-flex justify-content-between '>
              <div className='sip-count-text'><b>Total Interest</b></div>
              <div><b>₹ {returnVal}</b></div>
            </div>
            <div className='d-flex justify-content-between pt-3 pb-4 mb-1'>
              <div className='sip-count-text'><b>Maturity Value</b></div>
              <div><b>₹ {totalVal}</b></div>
            </div>
          </div>
        </div>
        <div className='col-lg-4 col-12 mt-4 mb-5 pb-5 mb-lg-0'>

          <div className='background p-4 text-center'>
            <div className="pt-4"></div>
            <div className="d-flex justify-content-center">
              <div className="d-flex justify-content-center">
                <div className="circle-blue"></div>
                <div className="circle-text">Total Investment</div>
              </div>
              <div className="d-flex justify-content-center mx-4 pb-3">
                <div className="circle-pink"></div>
                <div className="circle-text">Total Interest</div>
              </div>
            </div>
            {<Example />}
          </div>
        </div>
        <div className="col-lg-8 col-12">
          <div >
            <span style={{ marginLeft: 10 }}>

              <strong>FAQs</strong>
            </span>
          </div>
          <div class="accordion mt-0 mb-5" id="RDCaccordionExample1">
            <div class="accordion-item">
              <h2 class="accordion-header" id="RDCheadingOne">
                <button
                  class="accordion-button collapsed px-1 shadow-none"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#RDCcollapseOne"
                  aria-expanded="true"
                  aria-controls="RDCcollapseOne"
                >
                  &nbsp;&nbsp;What is Recurring Deposit?
                </button>
              </h2>
              <div
                id="RDCcollapseOne"
                class="accordion-collapse collapse"
                aria-labelledby="RDCheadingOne"
                data-bs-parent="#RDCaccordionExample1"
              >
                <div class="accordion-body px-1">
                  <>
                    <p id="isPasted">
                      A <strong>Recurring Deposit</strong> is a special kind of term deposit offered by Banks and Post Office which helps
                      people with regular incomes to deposit a fixed amount every month into their recurring deposit
                      account and earn interest at the rate applicable to fixed deposits. It is similar to making fixed
                      deposits of a certain amount in monthly instalments.
                    </p>
                  </>
                </div>
              </div>
            </div>
            <div class="accordion-item  pb-md-0" id="RDCaccordionExample1">
              <h2 class="accordion-header" id="RDCheadingTwo">
                <button
                  class="accordion-button collapsed px-1 shadow-none"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#RDCcollapseTwo"
                  aria-expanded="false"
                  aria-controls="RDCcollapseTwo"
                >
                  &nbsp; Products that offer similar + added benefits.
                </button>
              </h2>
              <div
                id="RDCcollapseTwo"
                class="accordion-collapse collapse"
                aria-labelledby="RDCheadingTwo"
                data-bs-parent="#RDCaccordionExample1"
              >
                <div class="accordion-body px-1">
                  <p style={{ textAlign: "left" }} id="isPasted">
                    Insurance options that offer some investment or savings features similar to RD, you might consider
                    the following:<br /><br />
                    1.<strong> Endowment Plans:</strong> Endowment insurance policies offer a combination of insurance
                    coverage and savings. These policies provide a lump sum payout at maturity, making them
                    similar to long-term savings plans. They typically offer guaranteed returns along with
                    bonuses.<br /><br />
                    2. <strong>Money-Back Policies:</strong> Money-back insurance policies provide periodic payouts (usually a
                    percentage of the sum assured) during the policy term. These policies can be considered a
                    form of savings with an insurance component.<br /><br />
                    3. <strong>Unit-Linked Insurance Plans (ULIPs):</strong> ULIPs combine insurance coverage with an investment
                    component. Part of your premium goes toward insurance, while the remainder is invested in
                    various funds like equity, debt, or balanced funds. ULIPs offer market-linked returns and can
                    provide flexibility in investment choices.<br /><br />
                    4. <strong>Single Premium Insurance Policies:</strong> These policies allow you to make a lump sum premium
                    payment, and they typically offer an insurance cover along with a savings or investment
                    component. Returns may vary based on the policy terms.<br /><br />
                    5. <strong>Whole Life Insurance with Cash Value:</strong> Certain whole life insurance policies accumulate
                    cash value over time, which can be accessed or borrowed against. The cash value
                    component can serve as a long-term savings and investment option. It&#39;s important to note
                    that insurance products with investment components often come with associated costs,
                    including charges and fees.
                    When considering insurance products with investment or savings features, carefully review the
                    policy terms, assess the returns, understand the cost structure, and ensure the product aligns with
                    your financial goals and risk tolerance. Consult with a financial advisor to make informed decisions
                    based on your specific needs and objectives, consider diversifying your investments across different
                    asset classes to achieve your financial goals more effectively.
                  </p>

                </div>
                {/* <div className="pb-5"></div> */}
              </div>
              {/* <div className="pb-5"></div> */}
            </div>
            <div class="accordion-item" id="RDCaccordionExample1">
            <h2 class="accordion-header" >
              <button
                class="accordion-button collapsed px-1 shadow-none"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#RDCheadingFour"
                aria-expanded="false"
                aria-controls="RDCheadingFour"
              >
                &nbsp; How to use this Recurring Deposit Calculator?
              </button>
            </h2>
            <div
              id="RDCheadingFour"
              class="accordion-collapse collapse"
              aria-labelledby="RDCheadingFour"
              data-bs-parent="#RDCaccordionExample1"
            >
              <div class="accordion-body px-1">
              To calculate your Recurring Deposit (RD), follow these steps:<br/><br/>
              <strong>1.Monthly Investment:</strong> Enter manually or use the slider.<br/>
              <strong>2.Rate of Interest (P.A):</strong> Enter manually or use the slider.<br/>
              <strong>3.Time Period Frequency:</strong>Choose between Year or Month options.<br/>
              <strong>4.Time Period:</strong> Enter manually or use the slider.<br/><br/>
              <strong>Results Display:</strong> Upon completing all details, the result will display at the bottom of the
              screen.<br/><br/>
              <strong>Additional Functionality:</strong><br/>
              <strong> Pie Chart Feature:</strong><br/>
              <strong>1.Total Investment:</strong> Shows the total investment.<br/>
              <strong>2.Total Interest:</strong> Displays the total Interest.<br/><br/>
              <strong>Tooltips:</strong><br/>
              <strong>1.On PC:</strong>  Mouse hover over Total Investment for a tooltip displaying the
              invested amount.<br/>
              <strong>2.On PC:</strong> Mouse hover over Total Interest for a tooltip displaying the interest
              amount.<br/>
              <strong>3.On Mobile:</strong> Tap & hold on Total Investment for a tooltip showing the
              invested amount.<br/>
              <strong>4.On Mobile:</strong> Tap & hold on Total Interest for a tooltip displaying the interest
              amount.<br/><br/>
              Effortlessly plan your Recurring Deposit today!
              </div>
            </div>
          </div>
          </div>

        </div>
        <div className="pb-5"></div>
      </div>
    </div>
    </div>
    </div>
  )
}