import React, { useEffect, useState } from "react";
import "./fdcalculator.css"
import { Tooltip as ReactTooltip } from "react-tooltip";
import 'react-tooltip/dist/react-tooltip.css'
import Slider from 'react-rangeslider';
import 'react-rangeslider/lib/index.css';
import Chart from 'react-apexcharts'
import { useNavigate } from "react-router-dom";
import Tabs from "../../components/tabs/tabs";

export default function FDCalculator() {
  const navigate = useNavigate()
  const [investValue, setInvestValue] = React.useState(25000);
  const [rate, setRate] = React.useState(12);
  const [time, setTime] = React.useState(10);
  const [investFlag, setInvestFlag] = React.useState(false);
  const [investErr, setInvestErr] = React.useState("");
  const [timetFlag, setTimeFlag] = React.useState(false);
  const [timetErr, setTimeErr] = React.useState("");
  const [ratetFlag, setRateFlag] = React.useState(false);
  const [ratetErr, setRateErr] = React.useState("");
  const [invsVal, setEnvVal] = React.useState("");
  const [returnVal, setReturnVal] = React.useState("");
  const [totalVal, setTotalVal] = React.useState("");
  const [days, setDaays] = React.useState("years");
  const [frequency, setFrequency] = React.useState("si");

  const [data, setData] = useState({
    options: {
      chart: {
        type: 'donut',
      },
      labels: [],
      tooltip: {
        y: {
          title: {
            formatter(seriesName) {
              return seriesName
            }
          }
        }
      },

      dataLabels: {
        dropShadow: {
          blur: 3,
          opacity: 1,
        }
      },
      colors: ['#0073BB', '#E05776'],
    },
    series: [],

  })

  const handleChangeComplete = () => {
  }

  useEffect(() => {
    if (investValue < 500) {
      setInvestFlag(true);
      setInvestErr("Minimum value allowed is 500")
    } else if (investValue > 10000000) {
      setInvestFlag(true);
      setInvestErr("Maximun value allowed is 10000000")
    } else {
      setInvestFlag(false);
      setInvestErr("")
    }
  }, [investValue]);

  useEffect(() => {
    if (rate < 1) {
      setRateFlag(true);
      setRateErr("Minimum value allowed is 1")
    } else if (rate > 15) {
      setRateFlag(true);
      setRateErr("Maximun value allowed is 15")
    } else {
      setRateFlag(false);
      setRateErr("")
    }
  }, [rate]);

  useEffect(() => {
    if (time == 0) {
      setTimeFlag(true);
      setTimeErr("Minimum value allowed is 1")
    } else if (time > 40) {
      setTimeFlag(true);
      setTimeErr("Maximun value allowed is 40")
    } else {
      setTimeFlag(false);
      setTimeErr("")
    }
  }, [time]);


  useEffect(() => {
    if (days == 'years') {
      if (time < 1) {
        setTimeFlag(true);
        setTimeErr("Minimum value allowed is 1")
      } else if (time > 10) {
        setTimeFlag(true);
        setTimeErr("Maximun value allowed is 10")
      } else {
        setTimeFlag(false);
        setTimeErr("")
      }
    } else if (days == "days") {
      if (time < 1) {
        setTimeFlag(true);
        setTimeErr("Minimum value allowed is 1")
      } else if (time > 3650) {
        setTimeFlag(true);
        setTimeErr("Maximun value allowed is 3650")
      } else {
        setTimeFlag(false);
        setTimeErr("")
      }
    } else {
      if (time < 1) {
        setTimeFlag(true);
        setTimeErr("Minimum value allowed is 1")
      } else if (time > 120) {
        setTimeFlag(true);
        setTimeErr("Maximun value allowed is 120")
      } else {
        setTimeFlag(false);
        setTimeErr("")
      }
    }

  }, [time, days]);

  const handleCalculation = (investment, rat, times) => {
    if (parseInt(investment) > 499 && parseInt(rat) > 0 && parseInt(times) > 0) {
      let amt = investment;
      let rate = rat;
      let year = times;
      if (days == "years") {
        if (frequency == "si") {
          const interestRate = rat / 100;
          const simpleInt = amt * interestRate * year;
          const amount = (parseInt(amt) + parseInt(simpleInt));
          let nf = new Intl.NumberFormat('en-IN');
          setEnvVal(nf.format(investment));
          setReturnVal(nf.format(simpleInt?.toFixed(0)));
          setTotalVal(nf.format(amount));
          let dt = data;
          dt.options.labels[0] = "Total Investment(₹) : " + " " + nf.format(investment);
          dt.options.labels[1] = "Total Returns(₹) : " + " " + nf.format(simpleInt?.toFixed(0));
          dt.series[0] = parseInt(investment)
          dt.series[1] = parseInt(simpleInt?.toFixed(0))
          setData(dt);
        } else {
          let freq = frequency == "cy" ? 1 : frequency == "cm" ? 12 : frequency == "cg" ? 4 : 2;
          let maturity = amt * Math.pow((1 + ((rate / 100) / freq)), freq * year);
          let interest = (maturity - amt)
          let nf = new Intl.NumberFormat('en-IN');
          setEnvVal(nf.format(investment));
          setReturnVal(nf.format(interest?.toFixed(0)));
          setTotalVal(nf.format(maturity?.toFixed(0)));
          let dt = data;
          dt.options.labels = []
          dt.options.labels[0] = "Total Investment(₹) : " + " " + nf.format(investment);
          dt.options.labels[1] = "Total Returns(₹) : " + " " + nf.format(interest?.toFixed(0));
          dt.series[0] = parseInt(investment)
          dt.series[1] = parseInt(interest?.toFixed(0))
          setData(dt);
        }
      }
      if (days == "months") {
        if (frequency == "si") {
          const interestRate = rat / 100;
          const simpleInt = amt * interestRate * year / 12;
          const amount = (parseInt(amt) + parseInt(simpleInt));
          let nf = new Intl.NumberFormat('en-IN');
          setEnvVal(nf.format(investment));
          setReturnVal(nf.format(simpleInt?.toFixed(0)));
          setTotalVal(nf.format(amount?.toFixed(0)));
          let dt = data;
          dt.options.labels = []
          dt.options.labels[0] = "Total Investment(₹) : " + " " + nf.format(investment);
          dt.options.labels[1] = "Total Returns(₹) : " + " " + nf.format(simpleInt?.toFixed(0));
          dt.series[0] = parseInt(investment)
          dt.series[1] = parseInt(simpleInt?.toFixed(0))
          setData(dt);
        } else {
          let freq = frequency == "cy" ? 1 : frequency == "cm" ? 12 : frequency == "cg" ? 4 : 2;
          let maturity = amt * Math.pow((1 + ((rate / 100) / freq)), freq * year / 12);
          let interest = (maturity - amt)
          let nf = new Intl.NumberFormat('en-IN');
          setEnvVal(nf.format(investment));
          setReturnVal(nf.format(interest?.toFixed(0)));
          setTotalVal(nf.format(maturity?.toFixed(0)));
          let dt = data;
          dt.options.labels = []
          dt.options.labels[0] = "Total Investment(₹) : " + " " + nf.format(investment);
          dt.options.labels[1] = "Total Returns(₹) : " + " " + nf.format(interest?.toFixed(0));
          dt.series[0] = parseInt(investment)
          dt.series[1] = parseInt(interest?.toFixed(0))
          setData(dt);
        }
      }
      if (days == "days") {
        const interestRate = rat / 100;
        const simpleInt = amt * interestRate * year / 365;
        const amount = (parseInt(amt) + parseInt(simpleInt));
        let nf = new Intl.NumberFormat('en-IN');
        setEnvVal(nf.format(investment));
        setReturnVal(nf.format(simpleInt?.toFixed(0)));
        setTotalVal(nf.format(amount?.toFixed(0)));
        let dt = data;
        dt.options.labels = []
        dt.options.labels[0] = "Total Investment(₹) : " + " " + nf.format(investment);
        dt.options.labels[1] = "Total Returns(₹) : " + " " + nf.format(simpleInt?.toFixed(0));
        dt.series[0] = parseInt(investment)
        dt.series[1] = parseInt(simpleInt?.toFixed(0))
        setData(dt);
      }
    }else{
      let nf = new Intl.NumberFormat('en-IN');
      

        setEnvVal(0);
        setReturnVal(0);
        setTotalVal(0);

        let dt = data;
        dt.options.labels = []
        dt.options.labels[0] = "Total Investment(₹) : " + " " + nf.format(0);
        dt.options.labels[1] = "Total Returns(₹) : " + " " + nf.format(0);
        dt.series[0] = 50
        dt.series[1] =50
        setData(dt);
    }
  }

  useEffect(() => {
    handleCalculation(investValue, rate, time);
  }, [investValue, rate, time, days, frequency]);

  function Example(props) {
    return (
      <Chart className='donut-chart' options={data.options} series={data.series} type="donut" />
    );
  }

  return (
    <div>
      <Tabs/>
    <div className="custome-row">
      
      <div className="container mt-5 pt-5">
        <div className='path-text'> <span className="ta-cursor" onClick={() => {
          navigate("/")
        }}>Calculator</span> &gt;Fixed Deposit Calculator</div>
        <div className='mt-4 fd-cal-text'>Fixed Deposit Calculator</div>
        <div className='row group-background mt-2 '>
          <div className='col-lg-8 col-12 mt-4'>
            <div className='background p-4' id="content-sip">
              <div>
                <div className='d-flex justify-content-between'>
                  <div>Total Investment</div>
                  <div className="d-flex" style={{ width: 115 }}>
                    <span>
                      {investFlag === true ? <svg data-tooltip-id="invest" style={{ color: '#E05776' }} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" height="18" width="18" class="cis93InfoIcon contentNegative"><path fill="none" d="M0 0h24v24H0z"></path><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-6h2v6zm0-8h-2V7h2v2z"></path></svg> : ""}
                    </span>&nbsp;
                    <ReactTooltip id="invest" place="top" content={investErr} />
                    <input style={{background: investFlag === true ? "rgba(187, 3, 0, 0.1)" : 'rgba(0, 115, 187, 0.10)', color: investFlag === true ? "#E05776" : '', paddingRight: 1 }} className={`form-control shadow-none sm-fl rd-max1 `} id={`${investFlag === true ? "fname1" : "fname"}`} value={"₹"} readOnly />
                    <input onChange={(e) => {
                      if (isNaN(e.target.value)) { } else if (e.target.value.includes("+") == true) { } else if (e.target.value.includes("-") == true) { } else if (e.target.value.includes(".") == true) { } else {
                        if (e.target.value > 10000000) {
                          setInvestValue(10000000);
                        } else if (e.target.value == "") { setInvestValue(0); } else if (e.target.value == 0) { setInvestValue(0); } else {
                          if (e.target.value.indexOf(0) == '0' && e.target.value.length > 1) {
                            let va = e.target.value.substring(1)
                            setInvestValue(va)
                          } else if (investValue == 0 && e.target.value?.split("")[1] == '0') {
                            let va = e.target.value?.split("")[0];
                            setInvestValue(va)
                          } else {
                            setInvestValue(e.target.value)
                          }
                        }
                      }
                    }} value={investValue} maxLength={10000000} style={{ background: investFlag === true ? "rgba(187, 3, 0, 0.1)" : 'rgba(0, 115, 187, 0.1)', color: investFlag === true ? "#E05776" : '', paddingLeft: 0, textAlign: "right" }} className={`shadow-none form-control sm-bg`} type="text" id={`${investFlag === true ? "fname1" : "fname"}`} name="fname" />
                  </div>
                </div>
                <Slider
                  min={500}
                  max={10000000}
                  value={investValue}
                  onChange={(value) => {
                    setInvestValue(value)
                  }}
                  onChangeComplete={handleChangeComplete}
                />
              </div>
              <div>
                <div className='d-flex justify-content-between '>
                  <div>Rate Of Interest (p.a)</div>
                  <div className="d-flex" style={{ width: 115 }}>
                    <span >
                      {ratetFlag === true ? <svg data-tooltip-id="rate" style={{ color: '#E05776' }} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" height="18" width="18" class="cis93InfoIcon contentNegative"><path fill="none" d="M0 0h24v24H0z"></path><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-6h2v6zm0-8h-2V7h2v2z"></path></svg> : ""}
                    </span>&nbsp;
                    <ReactTooltip id="rate" place="top" content={ratetErr} />
                    <input onChange={(e) => {
                      let vl = e.target.value
                      if (isNaN(e.target.value)) { } else if (e.target.value.includes("+") == true) { } else if (e.target.value.includes("-") == true) { } else {
                        if (rate != "0" && e.target.value > 15) { setRate(15) } else if (e.target.value === "") {
                          setRate(0)
                        } else {
                          if (e.target.value.includes(".") == true && e.target.value?.split("")[1] == "0" && e.target.value?.split("")[0] != 1) { setRate(0) } else if (e.target.value.indexOf(0) == '0' && e.target.value >= 1) {
                            let va = e.target.value.substring(1)
                            setRate(va)
                          } else if (rate == 0 && e.target.value?.split("")[1] == '0') {

                            let va = e.target.value?.split("")[0];
                            setRate(va)
                          } else {
                            if (e.target.value.includes(".") == true && e.target.value >= 1) {
                              let srr = e.target.value.split(".");
                              if (srr[1]?.length > 2) {
                              } else {
                                let str = (e?.target?.value)
                                setRate(str)
                              }
                            } else if (e.target.value >= 1) {
                              let str = e?.target?.value
                              setRate(str)
                            }
                          }
                        }
                      }
                    }} value={rate} className='shadow-none form-control sm-bgr' style={{ color: ratetFlag === true ? "#E05776" : '', paddingRight: 4, textAlign: "right" }} type="text" id={`${ratetFlag === true ? "fname1" : "fname"}`} name="fname" />
                    <span style={{ background: ratetFlag === true ? "rgba(187, 3, 0, 0.1)" : 'rgba(0, 115, 187, 0.10)', color: ratetFlag === true ? "#E05776" : '', marginLeft: 2, paddingLeft: 0 }} className='shadow-none form-control sm-fr wdthcls' id={`${ratetFlag === true ? "fname1" : "fname"}`}>%</span>
                  </div>
                </div>
                <Slider
                  min={1}
                  max={15}
                  value={rate}
                  onChange={(value) => {
                    setRate(value?.toFixed(2))
                  }}
                  onChangeComplete={handleChangeComplete}
                  step={0.1}
                />
                <div className='d-flex justify-content-between'>
                  <div className="row" style={{ width: "34%" }}>
                    <div className="col-md-6">
                      Time Period
                    </div>
                    <div className="col-md-6">
                      <select className="form-control shadow-none" onChange={(e) => {
                        setDaays(e.target.value);
                        if (e.target.value == "years") {
                          if (time > 10) {
                            setTime(10)
                          }
                        }
                        if (e.target.value == "months") {
                          if (time > 120) {
                            setTime(120)
                          }
                        }
                      }}>
                        <option value="years">Years</option>
                        <option value="months">Months</option>
                        <option value="days">Days</option>
                      </select>
                    </div>
                  </div>
                  <div className="d-flex" style={{ width: 115 }}>
                    <span>
                      {timetFlag === true ? <svg data-tooltip-id="rate" style={{ color: '#E05776' }} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" height="18" width="18" class="cis93InfoIcon contentNegative"><path fill="none" d="M0 0h24v24H0z"></path><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-6h2v6zm0-8h-2V7h2v2z"></path></svg> : ""}
                    </span>&nbsp;
                    <ReactTooltip id="rate" place="top" content={timetErr} />
                    <div className="d-flex">
                      <input onChange={e => {
                        if (isNaN(e.target.value)) { } else if (e.target.value.includes("+") == true) { } else if (e.target.value.includes("-") == true) { } else if (e.target.value.includes(".") == true) { } else {
                          if (days == "years") {
                            if (time != "0" && e.target.value > 10) { setTime(10) } else if (e.target.value === "") {
                              setTime(0)
                            } else {
                              if (time == 0 && e.target.value?.split("")[1] == '0') {
                                let va = e.target.value?.split("")[0];
                                setTime(va)
                              } else {
                                let str = Number(e.target.value).toString();
                                setTime(str)
                              }
                            }
                          } else if (days == "months") {
                            if (time != "0" && time > 120 || e.target.value > 120) { setTime(120) } else if (e.target.value === "") {
                              setTime(0)
                            } else {
                              if (time == 0 && e.target.value?.split("")[1] == '0') {
                                let va = e.target.value?.split("")[0];
                                setTime(va)
                              } else {
                                let str = Number(e.target.value).toString();
                                setTime(str)
                              }
                            }
                          } else {
                            if (time != "0" && e.target.value > 3650) { setTime(3650) } else if (e.target.value === "") {
                              setTime(0)
                            } else {
                              if (time == 0 && e.target.value?.split("")[1] == '0') {
                                let va = e.target.value?.split("")[0];
                                setTime(va)
                              } else {
                                let str = Number(e.target.value).toString();
                                setTime(str)
                              }
                            }
                          }
                        }
                      }} value={time} className='shadow-none form-control' style={{ color: timetFlag === true ? "#E05776" : '', textAlign: "right", marginLeft: 10, height: 32 }} type="text" id={`${timetFlag === true ? "fname1" : "fname"}`} name="fname" />
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <Slider
                  min={1}
                  max={days === 'years' ? 10 : days == "days" ? 3650 : 120}
                  value={time}
                  onChange={(value) => {
                    setTime(value)
                  }}
                  onChangeComplete={handleChangeComplete}
                />
              </div>
              <div className="d-flex justify-content-between">
                <div>Frequency</div>
                <div>
                  <select class="form-control shadow-none" style={{ textAlign: 'right' }} onChange={(e) => {
                    setFrequency(e.target.value);
                  }} value={frequency}>
                    <option value="si">Simple Interest</option>
                    <option value="cm">Compounded Monthly</option>
                    <option value="cg">Compounded Quaterly</option>
                    <option value="chy">Compounded Half Yearly</option>
                    <option value="cy">Compounded Yearly</option>
                  </select>
                </div>
              </div>
              <div className='d-flex justify-content-between pb-4'>
                <div className='sip-count-text mt-4'><b>Invested Amount</b></div>
                <div className="mt-4"><b>₹ {invsVal}</b></div>
              </div>
              <div className='d-flex justify-content-between '>
                <div className='sip-count-text'><b>Total Returns</b></div>
                <div><b>₹ {returnVal}</b></div>
              </div>
              <div className='d-flex justify-content-between pt-3 pb-4 mb-1'>
                <div className='sip-count-text'><b>Total Value</b></div>
                <div><b>₹ {totalVal}</b></div>
              </div>
            </div>
            {/* <hr style={{ border: '2px solid' }} /> */}
          </div>

          <div className='col-lg-4 col-12 mt-4 mb-5 pb-5 mb-lg-0'>
            <div className='background p-4 text-center'>
              <div className="pt-4"></div>
              <div className="d-flex justify-content-center">
                <div className="d-flex justify-content-center">
                  <div className="circle-blue"></div>
                  <div className="circle-text">Total Investment</div>
                </div>
                <div className="d-flex justify-content-center mx-4 pb-3">
                  <div className="circle-pink"></div>
                  <div className="circle-text">Total Returns</div>
                </div>
              </div>
              {<Example />}
              {/* <div className="pb-5"></div> */}
            </div>
          </div>

          <div className="col-lg-8 col-12">
            <div >
              <span style={{ marginLeft: 10 }}>

                <strong>FAQs</strong>
              </span>
            </div>
            <div class="accordion mt-0 mb-5" id="FDaccordionExample1">
              <div class="accordion-item">
                <h2 class="accordion-header" id="FDheadingOne">
                  <button
                    class="accordion-button collapsed px-1 shadow-none"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#FDcollapseOne"
                    aria-expanded="true"
                    aria-controls="FDcollapseOne"
                  >
                    &nbsp;&nbsp;What is Fixed Deposit?
                  </button>
                </h2>
                <div
                  id="FDcollapseOne"
                  class="accordion-collapse collapse"
                  aria-labelledby="FDheadingOne"
                  data-bs-parent="#FDaccordionExample1"
                >
                  <div class="accordion-body px-1">
                    <>
                      <p id="isPasted">
                        FDs are a form of fixed-income investment where you deposit a lump sum amount with a bank or
                        financial institution, and they pay you a predetermined interest rate over a fixed period.
                      </p>
                    </>
                  </div>
                </div>
              </div>
              <div class="accordion-item " id="FDaccordionExample1">
                <h2 class="accordion-header" id="FDheadingTwo">
                  <button
                    class="accordion-button collapsed px-1 shadow-none"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#FDcollapseTwo"
                    aria-expanded="false"
                    aria-controls="FDcollapseTwo"
                  >
                    &nbsp; Products that offer similar + added benefits.
                  </button>
                </h2>
                <div
                  id="FDcollapseTwo"
                  class="accordion-collapse collapse"
                  aria-labelledby="FDheadingTwo"
                  data-bs-parent="#FDaccordionExample1"
                >
                  <div class="accordion-body px-1">
                    <p style={{ textAlign: "left" }} id="isPasted">
                      <p>
                        In contrast, insurance products are primarily designed to provide protection and risk coverage,
                        although some insurance-linked products may have an investment or savings component. That said,
                        here are some insurance products with savings or investment features that may offer stability or
                        guaranteed returns similar to FDs:<br />
                      </p>
                      1.<strong>Endowment Plans:</strong> Endowment insurance policies offer
                      both insurance coverage and a savings element. These policies provide a lump
                      sum payout at maturity, which can be viewed as a
                      form of savings. They often come with guaranteed returns and may offer
                      bonuses.
                      <br /><br />
                      2.<strong>Whole Life Insurance with Cash Value:</strong> Certain whole
                      life insurance policies accumulate cash value over time. This cash value can
                      serve as a long- term savings component. While it may not
                      provide fixed returns like FDs, it offers a conservative and stable growth
                      option.
                      <br /><br />
                      3.<strong>Guaranteed Savings Plans:</strong> Some insurance products,
                      such as guaranteed savings plans, provide assured returns over a fixed tenure.
                      These plans may guarantee a minimum return, offering some
                      level of stability.
                      <br /><br />
                      4.<strong>Single Premium Insurance Policies:</strong> These policies
                      allow you to make a lump sum premium payment, and they typically offer an
                      insurance coveralong with a savings or
                      investment component. Returns may vary based on the policy terms.
                      <br /><br />
                      5.<strong>Annuity Plans:</strong> Annuity plans, which can be considered
                      a form of insurance, provide regular income payouts after a lump sum payment.
                      While notexactly like FDs, they can provide
                      a steady income stream in retirement. It's essential to understand that the
                      returns from insurance-linked savings or investment
                      products are often lower than what you might get from FDs. Additionally,
                      insurance products may come with charges and fees, and their returns can vary based on factors like market performance
                      and policy terms. When considering insurance products with savings or
                      investment features, carefully review the policy terms, assess the returns,
                      understand the cost structure, and ensure the product aligns with your
                      financial goals  and risk tolerance. It's
                      also advisable to consult with a financial advisor to make informed decisions
                      tailored to your specific needs and objectives.
                    </p>

                  </div>
                  {/* <div className="pb-5"></div> */}
                </div>
                {/* <div className="pb-5"></div> */}
              </div>
              <div class="accordion-item" id="FDaccordionExample1">
                <h2 class="accordion-header" >
                  <button
                    class="accordion-button collapsed px-1 shadow-none"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#FDheadingFour"
                    aria-expanded="false"
                    aria-controls="FDheadingFour"
                  >
                    &nbsp; How to use Fixed Deposit calculator?
                  </button>
                </h2>
                <div
                  id="FDheadingFour"
                  class="accordion-collapse collapse"
                  aria-labelledby="FDheadingFour"
                  data-bs-parent="#FDaccordionExample1"
                >
                  <div class="accordion-body px-1">
                 <strong> To calculate your Fixed Deposit (FD), follow these steps:</strong><br/><br/>
                  <strong>1.</strong> <strong>Total Investment:</strong> Use the slider or manually enter the amount.<br/>
                 <strong>2.</strong> <strong>Rate of Interest (p.a):</strong> Use the slider or manually enter the rate.<br/>
                 <strong>3.</strong> <strong>Time Period Frequency:</strong> Select from Years, Months, or Days via dropdown.<br/>
                 <strong>4.</strong><strong>Time Period:</strong> Use the slider or manually enter the duration.<br/>
                 <strong>5.Interest Calculation Frequency:</strong>  Choose Simple Interest, Compounded Monthly,<br/>
                  Quarterly, Half-Yearly, or Yearly from the dropdown.<br/><br/>
                 <strong> Note:</strong> Upon completing all required fields, the calculated amounts will display on the
                  bottom side of the screen.<br/><br/>
                 <strong> Additional Functionality:</strong><br/>
                 <strong>Pie Chart Feature:</strong><br/>
                 <strong>1.Total Investment:</strong>  Shows the total investment.<br/>
              <strong>2.Total Interest:</strong>  Displays the total Interest.<br/><br/>
              <strong>Tooltips:</strong><br/>
                 
                  <strong>1. On PC:</strong> Mouse hover over Total Investment for a tooltip displaying the invested
                  amount.<br/>
                  <strong>2.On PC:</strong>  Mouse hover over Total Return for a tooltip displaying the return amount.<br/>
                  <strong>3.On Mobile:</strong>  Tap & hold on Total Investment for a tooltip displaying the invested
                  amount.<br/>
                  <strong>4.On Mobile:</strong>  Tap & hold on Total Return for a tooltip displaying the return amount.<br/>
                  Start planning your Fixed Deposit today!
                   </div>
                </div>
              </div>
            </div>
          </div>
          <div className="pb-5"></div>
        </div>
      </div>
    </div>
    </div>
  )
}