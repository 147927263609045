import React, { useEffect, useState } from "react";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
  } from 'chart.js';
  import { Line } from 'react-chartjs-2';

  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
  );

  

const MedicalModalComponent = (props) => {
    const options = {
        // scales: {
        //     yAxes: [
        //       {
        //         ticks: {
        //           beginAtZero: false
        //         }
        //       }
        //     ]
        //   },
        scales: {
            y: {
                ticks: {
                    // Include a dollar sign in the ticks
                    callback: function(value, index, ticks) {
                        return new Intl.NumberFormat('en-IN').format(parseInt(value)) ;
                        // return 'Premium ' + new Intl.NumberFormat('en-IN').format(parseInt(value)) ;
                    }
                }
            }
        },
        
        responsive: true,
        plugins: {
          legend: {
            display:false,
            position: 'top',
          },
          title: {
            display: true,
            text: 'Premium',
            position:"left"
          },
        },
      };
      const labels = props?.graphData?.map((val,i)=>(parseInt(val?.age)+i));
    //    const labels = props?.graphData?.map((val,i)=>"Age "+(parseInt(val?.age)+i));
      
       const data = {
        labels,
        datasets: [
          {
            label: 'Total',
            data: props?.graphData?.map(val=>parseFloat(val?.total)),
            borderColor: '#0073BB',
            backgroundColor: '#0073BB',
          }
        ],
      };
   
    const [dataList, setDataList] = useState([]);
      useEffect(() => {
        console.log(props?.graphData)
        if(props?.graphData?.length>70){
            let av=[];
             for(let i=0;i<=70;i++){
                av.push(props?.graphData[i])
             }
        }else{

            setDataList(props?.graphData);
        }

    }, [])


    return <React.Fragment>


<Line options={options} data={data}  />

<div className="row text-center">
    <h1 id="ages" >Age</h1>
</div>

        <table class="table table-hover mt-4">
            <thead>
                <tr>
                    <th scope="col">Age</th>
                    <th scope="col">Premium</th>
                    <th scope="col">Inflation rate(10%)</th>
                    <th scope="col">18% of GST</th>
                    <th scope="col">Total Amount</th>
                </tr>
            </thead>
            <tbody>
                {dataList?.map((val, i) => (<tr>
                    {val?.premium!=undefined?<td>{val?.age+i}</td>:""}
                    {val?.premium!=undefined?<td>{val?.premium}</td>:""}
                    {val?.premium!=undefined? <td>{val?.interest}</td>:""}
                    {val?.premium!=undefined? <td>{val?.gst}</td>:""}
                    {val?.premium!=undefined? <td>{val?.total}</td>:""}
                    
                   {i<=70}
                </tr>))}
                {/* <tr><th colSpan="4">*Exclusive of 18% GST</th></tr> */}
            </tbody>
        </table>

    </React.Fragment>
}
export default MedicalModalComponent;