import React from 'react'
import Hamburger from '../../../assets/images/hamburger.png'
import Logo from '../../../assets/images/D_logo.png'
import '../header/header.css'

function openNav() {
    document.getElementById("mySidenav").style.width = "250px";
} 
function closeNav() {
    document.getElementById("mySidenav").style.width = "0";
    console.log(window.location.hash)
    window.location.href=window.location.hash
    setTimeout(() => {
      
      //  window.location.reload();
    }, 500);
}
export default function Header() {
    
  return (
    // <nav className="navbar navbar-light d-flex justify-content-between px-4">
    //     <div className='main-logo'>
    //         <a className="navbar-brand" href="#"><img className='py-3' src={Logo}/></a>
    //     </div>
    //     <div className='d-flex'>
    //         <div className='man-img'>
    //             {/* <img src={Man}/> */}
    //         </div>
    //         <div className='my-auto'>
    //             {/* <div className='name'>Nathan</div>
    //             <div className='sub-name'>Nathan</div> */}
    //         </div>
    //     </div>
    // </nav>
    <>
    <nav class="navbar navbar-expand-lg navbar-light bg-light">
        <div class="container-fluid">
            <div className='main-logo px-lg-5 px-0'>
                <a className="navbar-brand" href="#"><img className='py-3' src={Logo}/></a>
            </div>
            <div id="mySidenav" class="sidenav d-lg-none">
                <a href="javascript:void(0)" class="closebtn" onClick={closeNav}>&times;</a>
                <a href="#/fd-calulator" onClick={closeNav}>FD Calculator</a>
                <a href="#/term-calulator" onClick={closeNav}>Term Insurance Calculator</a>
                <a href="#/child-calulator" onClick={closeNav}>Child Education Calculator</a>
                <a href="#/rd-calulator" onClick={closeNav}>Recurring Calculator</a>
                <a href="#/sip-calulator" onClick={closeNav}>SIP Calculator</a>
                <a href="#/retirment-calulator" onClick={closeNav}>Retirement Calculator</a>
                <a href="#/ppf-calulator" onClick={closeNav}>PPF Calculator</a>
                <a href="#/incometax-calulator" onClick={closeNav}>Income Tax Calculator</a>
                <a href="#/cagr-calulator" onClick={closeNav}>CAGR Calculator</a>
                {/* <a href="#/mutual-calulator" onClick={closeNav}>Mutual Fund Calculator</a> */}
                <a href="#/future-calulator" onClick={closeNav}>Future Value Calculator</a>
                <a href="#/present-calulator" onClick={closeNav}>Present Value Calculator</a>
                <a href="#/medical-calulator" onClick={closeNav}>Medical Calculator</a>
            </div>

            <span className='d-lg-none' onClick={openNav} style={{cursor: 'pointer', padding: 5}}><img src={Hamburger}/></span>
        </div>

    
    {/* <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <ul class="navbar-nav me-auto mb-2 mb-lg-0">
        <li class="nav-item d-lg-none">
          <a class="nav-link" aria-current="page" href="#">FD Calculator</a>
        </li>
        <li class="nav-item d-lg-none">
          <a class="nav-link" href="#">Term Insurance Calculator</a>
        </li>
        <li class="nav-item d-lg-none">
          <a class="nav-link">Child Education Calculator</a>
        </li>
        <li class="nav-item d-lg-none">
          <a class="nav-link">Recurring Calculator</a>
        </li>
        <li class="nav-item d-lg-none">
          <a class="nav-link">SIP Calculator</a>
        </li>
        <li class="nav-item d-lg-none">
          <a class="nav-link">Retirement Calculator</a>
        </li>
        <li class="nav-item d-lg-none">
          <a class="nav-link">PPF Calculator</a>
        </li>
        <li class="nav-item d-lg-none">
          <a class="nav-link">Income Tax Calculator</a>
        </li>
      </ul>
    </div>
  </div> */}
</nav>
    </>
  )
}
