import React, { useEffect, useState } from "react";
import "./PresentValueFutureMoney.css"
import { Tooltip as ReactTooltip } from "react-tooltip";
import 'react-tooltip/dist/react-tooltip.css'
import Slider from 'react-rangeslider';
import 'react-rangeslider/lib/index.css';
import Chart from 'react-apexcharts'
import { useNavigate } from "react-router-dom";
import Tabs from "../../components/tabs/tabs";


export default function PresentValueFutureMoney() {
    const navigate = useNavigate()
    const [futureValue, setFutureValue] = React.useState(1000);
    const [rate, setrate] = React.useState(5);
    const [duration, setDuration] = React.useState(1);
    const [futureFlag, setFutureFlag] = React.useState(false);
    const [futureErr, setFutureErr] = React.useState("");
    const [durationFlag, setDurationFlag] = React.useState(false);
    const [durationErr, setDurationErr] = React.useState("");
    const [rateFlag, setRateFlag] = React.useState(false);
    const [rateErr, setRateErr] = React.useState("");
    const [presentVal, setPresentVal] = React.useState("");
    const [totalInt, setTotalInt] = React.useState("");
    const [data, setData] = useState({
        options: {
            chart: {
                type: 'donut',
            },
            labels: [],
            tooltip: {
                y: {
                    title: {
                        formatter(seriesName) {
                            return seriesName
                        }
                    }
                }
            },
            dataLabels: {
                dropShadow: {
                    blur: 3,
                    opacity: 0.8,
                }
            },
            colors: ['#0073BB', '#E05776'],
        },
        series: [],

    })

    const handleChangeComplete = () => {
    }
    useEffect(() => {
       
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, []);
    useEffect(() => {
        if (futureValue <=0) {
            setFutureFlag(true);
            setFutureErr("Minimum value should be greater than 0")
        }
        else if (futureValue > 10000000) {
            setFutureFlag(true);
            setFutureErr("Maximum value should not be greater than 10000000")
        }else {
            setFutureFlag(false);
            setFutureErr("")
        }
    }, [futureValue]);

    useEffect(() => {
      if (rate <= 0) {
        setRateFlag(true);
          setRateErr("Minimum value should be greater than 0")
      } else {
        setRateFlag(false);
          setRateErr("")
      }
  }, [rate]);


    useEffect(() => {
        if (duration <=0 ) {
            setDurationFlag(true);
            setDurationErr("Minimum value should be greater than 0")
        }else {
            setDurationFlag(false);
            setDurationErr("")
        }
    }, [duration]);

    const handleCalculation = (futureValue1, duration, rate) => {
let future_value=parseFloat(futureValue1);//Future Investment
let rate_ofinterest=parseFloat(rate);//Rate of Interest
let number_ofperiods=parseFloat(duration);//Number of Periods
rate_ofinterest=rate_ofinterest/100;
let present_value=0;
let total_interest=0;
if(future_value>0 && rate_ofinterest>0 &&  number_ofperiods>0){
 present_value = future_value / Math.pow(1 + rate_ofinterest, number_ofperiods);
total_interest=future_value-present_value;
let nf = new Intl.NumberFormat('en-IN');
           setPresentVal(nf.format(present_value?.toFixed(2)));
           setTotalInt(nf.format(total_interest?.toFixed(2)));
           let dt = data;
           dt.options.labels[0] = "Present Value of Investment(₹) : " + " " +'₹'+" " + nf.format(present_value?.toFixed(2));
           dt.options.labels[1] = "Total Interest(₹) : " + " " +'₹'+" " + nf.format(total_interest?.toFixed(2));
           dt.series[0] = present_value
           dt.series[1] = total_interest
           setData(dt); 
    }else{
        let nf = new Intl.NumberFormat('en-IN');
        setPresentVal(0);
        setTotalInt(0);
       
        let dt = data;
        dt.options.labels[0] = "Present Value of Investment(₹) : " + " " + '₹' + " " + nf.format(0);
        dt.options.labels[1] = "Total Interest(₹) : " + " " + '₹' + " " + nf.format(0);
        dt.series[0] = 50
        dt.series[1] = 50
        setData(dt);
    }
}


    useEffect(() => {
        handleCalculation(futureValue, duration,rate);
    }, [futureValue, duration,rate]);

    function Example(props) {
        return (
            <Chart className='donut-chart' options={data.options} series={data.series} type="donut" />
        );
    }

    return (
        <div>
            <Tabs/>
        <div div className="custome-row">
            <div className="container mt-5 pt-5">
                <div className='path-text '> <span className="ta-cursor" onClick={() => {
                    navigate("/")
                }}>Calculator</span> &gt; Present Value Future Money Calculator</div>
                <div className='mt-4 fd-cal-text'> Present Value Future Money Calculator</div>
                <div className='row group-background'>
                    <div className='col-lg-8 col-12 mt-4'>
                        <div className='background p-4' id="content-sip">
                            <div>
                                <div className='d-flex justify-content-between'>
                                    <div>Future Value</div>
                                    <div className="d-flex" style={{ width: 115}}>
                                        <span>
                                            {futureFlag === true ? <svg data-tooltip-id="futureValue" style={{ color: '#E05776' }} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" height="18" width="18" class="cis93InfoIcon contentNegative"><path fill="none" d="M0 0h24v24H0z"></path><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-6h2v6zm0-8h-2V7h2v2z"></path></svg> : ""}
                                        </span>&nbsp;
                                        <ReactTooltip id="futureValue" place="top" content={futureErr} />
                                        <input style={{ background: futureFlag === true ? "rgba(187, 3, 0, 0.1)" : 'rgba(0, 115, 187, 0.10)', color: futureFlag === true ? "#E05776" : '' }} className={`form-control shadow-none sm-fl rd-max1`} id={`${futureFlag === true ? "fname1" : "fname"}`} value={"₹"} readOnly />
                                        <input onChange={e => {
                                            if (isNaN(e.target.value)) { } else if (e.target.value.includes("+") == true) { } else if (e.target.value.includes("-") == true) { } else if (e.target.value.includes(".") == true) { }else {
                                                if (e.target.value > 10000000) { setFutureValue(10000000); } else if (e.target.value == "") { setFutureValue(0); } else if (e.target.value == 0) { setFutureValue(0); } else {
                                                    if (e.target.value.indexOf(0) == '0' && e.target.value.length > 1) {
                                                        let va = e.target.value.substring(1)
                                                        setFutureValue(va)
                                                    } else if (futureValue == 0 && e.target.value?.split("")[1] == '0') {
                                                        let va = e.target.value?.split("")[0];
                                                        setFutureValue(va)
                                                    } else {
                                                        setFutureValue(e.target.value)
                                                    }
                                                }
                                            }
                                        }} value={futureValue} maxLength={10000000} style={{ background: futureFlag === true ? "rgba(187, 3, 0, 0.1)" : 'rgba(0, 115, 187, 0.10)', color: futureFlag === true ? "#E05776" : '', paddingLeft: 0, textAlign: "right" }} className={`shadow-none form-control sm-bg`} type="text" id={`${futureFlag === true ? "fname1" : "fname"}`} name="fname" />
                                    </div>
                                </div>
                                <Slider
                                        min={1}
                                        max={10000000}
                                        value={futureValue}
                                        onChange={(value) => {
                                            setFutureValue(value)
                                        }}
                                        onChangeComplete={handleChangeComplete}
                                        step={1000}


                                    />
                            </div>
                        
                            <div>
                                <div className='d-flex justify-content-between'>

                                    <div>Rate of Interest</div>
                                    <div className="d-flex" style={{ width: 115 }}>
                                        <span >
                                            {rateFlag === true ? <svg data-tooltip-id="rate" style={{ color: '#E05776' }} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" height="18" width="18" class="cis93InfoIcon contentNegative"><path fill="none" d="M0 0h24v24H0z"></path><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-6h2v6zm0-8h-2V7h2v2z"></path></svg> : ""}
                                        </span>&nbsp;
                                        <ReactTooltip id="rate" place="top" content={rateErr} />

                                        <input
                                            onChange={e => {
                                                const inputValue = e.target.value;
                                                if (isNaN(e.target.value)) { } else if (e.target.value.includes("+") == true) { } else if (e.target.value.includes("-") == true) { } else if(e.target.value.includes(".")==true && e.target.value?.split(".")[1]?.length>2){}
                                                else if (parseFloat(inputValue) === 100 && inputValue.includes(".")) {
                                                    e.preventDefault();
                                                }  else {
                                                    if (rate != "0" && e.target.value > 100) { setrate(100) } else if (e.target.value == "") { setrate(0); }
                                                   
                                                    if (e.target.value == 0) { setrate(0); } else {
                                                        if (e.target.value.indexOf(0) == '0' && e.target.value.length > 1) {
                                                            let va = e.target.value.substring(1)
                                                            setrate(va)
                                                        } else if (rate == 0 && e.target.value?.split("")[1] == '0') {
                                                            let va = e.target.value?.split("")[0];
                                                            setrate(va)
                                                        } else {
                                                            setrate(e.target.value)
                                                        }
                                                    }
                                                }
                                                

                                            }} value={rate} className='shadow-none form-control sm-bgr absright' style={{ color: rateFlag === true ? "#E05776" : '', paddingLeft: 0, textAlign: "right", paddingRight: 2 }} type="text" id={`${rateFlag === true ? "fname1" : "fname"}`} name="fname" />
                                        <label style={{ background: rateFlag === true ? "rgba(187, 3, 0, 0.1)" : 'rgba(0, 115, 187, 0.10)', color: rateFlag === true ? "#E05776" : '', marginLeft: 2 }} className='shadow-none form-control sm-fr wdthcls' htmlFor={`${rateFlag === true ? "fname1" : "fname"}`} id={`${rateFlag === true ? "fname1" : "fname"}`}>%</label>
                                    </div>
                                </div>
                                <Slider
                                        min={1}
                                        max={100}
                                        value={rate}
                                        onChange={(value) => {
                                            setrate(value.toFixed(2))
                                        }}
                                        onChangeComplete={handleChangeComplete}
                                        step={0.1}
                                    />
                            </div>


                            
                            <div>
                                <div className='d-flex justify-content-between'>

                                    <div>Number of Periods</div>
                                    <div className="d-flex" style={{ width: 115 }}>
                                        <span >
                                            {durationFlag === true ? <svg data-tooltip-id="duration" style={{ color: '#E05776' }} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" height="18" width="18" class="cis93InfoIcon contentNegative"><path fill="none" d="M0 0h24v24H0z"></path><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-6h2v6zm0-8h-2V7h2v2z"></path></svg> : ""}
                                        </span>&nbsp;
                                        <ReactTooltip id="duration" place="top" content={durationErr} />

                                        <input
                                            onChange={e => {
                                                if (isNaN(e.target.value)) { } else if (e.target.value.includes(".") == true) { } else if (e.target.value.includes("+") == true) { } else if (e.target.value.includes("-") == true) { }  else {
                                                    if (duration != "0" && e.target.value > 100) { setDuration(100) } else if (e.target.value == "") { setDuration(0); } else if (e.target.value == 0) { setDuration(0); } else {
                                                        if (e.target.value.indexOf(0) == '0' && e.target.value.length > 1) {
                                                            let va = e.target.value.substring(1)
                                                            setDuration(va)
                                                        } else if (duration == 0 && e.target.value?.split("")[1] == '0') {
                                                            let va = e.target.value?.split("")[0];
                                                            setDuration(va)
                                                        } else {
                                                            setDuration(e.target.value)
                                                        }
                                                    }
                                                }

                                            }} value={duration} className='shadow-none form-control sm-bgr absright' style={{ color: durationFlag === true ? "#E05776" : '', paddingLeft: 0, textAlign: "right", paddingRight: 2 }} type="text" id={`${durationFlag === true ? "fname1" : "fname"}`} name="fname" />
                                        <label style={{ background: durationFlag === true ? "rgba(187, 3, 0, 0.1)" : 'rgba(0, 115, 187, 0.10)', color: durationFlag === true ? "#E05776" : '', marginLeft: 2 }} className='shadow-none form-control sm-fr wdthcls' htmlFor={`${durationFlag === true ? "fname1" : "fname"}`} id={`${durationFlag === true ? "fname1" : "fname"}`}>Yr</label>
                                    </div>
                                </div>
                                <Slider
                                    min={1}
                                    max={100}
                                    value={duration}
                                    onChange={(value) => {
                                        setDuration(value)
                                    }}
                                    onChangeComplete={handleChangeComplete}
                                />
                            </div>
                            <div className='d-flex justify-content-between pt-5 mt-5 pb-4 '>
                                <div className='sip-count-text mt-4'><b>Present Value of Investment</b></div>
                                <div className="mt-4"><b>₹ {presentVal}</b></div>
                            </div>
                            <div className='d-flex justify-content-between pt-1 pb-4 mb-1 '>
                                <div className='sip-count-text'><b>Total Interest</b></div>
                                <div><b>₹ {totalInt}</b></div>

                            </div>
                        </div>
                    </div>
                    <div className='col-lg-4 col-12 mt-4 mb-5 pb-5 mb-lg-0'>
                        <div className='background p-4 text-center'>
                            <div className="pt-4"></div>
                            <div className="d-flex justify-content-center">
                                <div className="d-flex justify-content-center">
                                    <div className="circle-blue"></div>
                                    <div className="circle-text">Present Value of Investment</div>
                                </div>
                                <div className="d-flex justify-content-center mx-4 pb-3">
                                    <div className="circle-pink"></div>
                                    <div className="circle-text">Total Interest</div>
                                </div>
                            </div>
                            {<Example />}
                            <div className="pb-5"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </div>
    )
}

