import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import "../child-education/child-education.css";
import { Tab, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import Professor from "../../../assets/images/professor.png";
import Doctor from "../../../assets/images/doctor.png";
import Scientist from "../../../assets/images/scientist.png";
import Media from "../../../assets/images/media.png";
import Engineer from "../../../assets/images/engineer.png";
import Pilot from "../../../assets/images/pilot.png";
import Child from "../../../assets/images/child-choice.png";
import "../retirement/rt.css";
import Tabs from "../../components/tabs/tabs";

export default function ChildEducation() {
  const [age, setAge] = useState([]);
  const [age1, setAge1] = useState([]);
  const [age2, setAge2] = useState([]);
  const [state, setState] = useState(true);
  let [infostore, setInfo] = useState({
    tempinflatedValue: "0",
    inflatedValue: "0",
    child_name: "",
    child_age: "0",
    child_need_age: "18",
    area: "A Professor",
    inflamation_rate: "4",
    save_money: "0",
    remain_val: "0",
    monthly_save_amount: "0",
    loan: "0",
    father_name: "",
    father_gender: "Male",
    father_age: "20",
    child_gender: "Male",
    current_amount_require: "0",
    investor_type: "Conservative",
    interest_rate: "5",
    tempremainval: "0",
    showhide: {
      Conservative: { display: "" },
      Moderate: { display: "none" },
      Aggressive: { display: "none" },
    },
    calculate_update: "",
    error_message: "",
  });

  useEffect(() => {
    const accordionItemHeaders = document.querySelectorAll(
      ".accordion-item-header"
    );
    accordionItemHeaders.forEach((accordionItemHeader) => {
      accordionItemHeader.addEventListener("click", (event) => {
        accordionItemHeader.classList.toggle("active");

        const accordionItemBody = accordionItemHeader.nextElementSibling;
        if (accordionItemHeader.classList.contains("active")) {
          accordionItemBody.style.maxHeight =
            accordionItemBody.scrollHeight + "px";
        } else {
          accordionItemBody.style.maxHeight = 0;
        }
      });
    });
  }, []);

  const error_message = useRef(null);

  const strRegexAlfa = new RegExp(/^[a-zA-Z ]*$/);
  const strRegexNume = new RegExp(/^[0-9]+$/i);
  useEffect(() => {
    let ag = [];
    for (let i = 20; i <= 57; i++) {
      ag.push(i);
    }
    setAge(ag);
    let ag1 = [];
    for (let i1 = 0; i1 <= 17; i1++) {
      ag1.push(i1);
    }
    setAge1(ag1);
    let ag2 = [];
    for (let i2 = 18; i2 <= 25; i2++) {
      ag2.push(i2);
    }
    setAge2(ag2);
  }, []);
  const navigate = useNavigate();
  const inflationCalculator = () => {
    var currentValue, annualInflationRate, period;
    //    if(e.target.id=="current_amount_require")
    //    {
    //      currentValue=parseFloat(e.target.value);
    //      annualInflationRate=parseFloat(infostore.inflamation_rate);
    //    }
    //    else
    {
      currentValue = parseFloat(infostore.current_amount_require);
      annualInflationRate = parseFloat(infostore.inflamation_rate);
    }
    if (isNaN(currentValue)) {
      currentValue = 0;
    }
    if (isNaN(annualInflationRate)) {
      annualInflationRate = 0;
    }
    period =
      parseFloat(infostore.child_need_age) - parseFloat(infostore.child_age);
    var inflatedValue =
      currentValue * Math.pow(1 + annualInflationRate / 100, period);
    let nf = new Intl.NumberFormat("en-IN");
    infostore.inflatedValue = nf.format(parseInt(inflatedValue));
    infostore.tempinflatedValue = parseInt(inflatedValue);
    infostore.remain_val = nf.format(
      parseInt(inflatedValue) - parseInt(infostore.save_money)
    );
    infostore.tempremainval =
      parseInt(inflatedValue) - parseInt(infostore.save_money);
    calculate_remaining();
  };
  const storeinfo = (e) => {
    if (e.target.id == "investor_type") {
      infostore.showhide[infostore.investor_type] = { display: "none" };
      infostore.showhide[e.target.value] = { display: "" };
    }
    setInfo({ ...infostore, [e.target.id]: e.target.value });
  };
  const calloan_val = () => {
    var remain_val = parseInt(infostore.tempremainval);
    var loan = remain_val + parseInt((remain_val * 12) / 100);
    var loan = parseInt(loan / 5 / 12);
    let nf = new Intl.NumberFormat("en-IN");
    infostore.loan = nf.format(loan);
  };
  const count_addi_money = () => {
    let monthly_save_amount = "0";
    //    if(e.target.id=="monthly_save_amount")
    //    {
    //     // monthly_save_amount=e.target.value;
    //    }
    //    else
    {
      monthly_save_amount = infostore.monthly_save_amount;
    }
    if (monthly_save_amount == "") {
      monthly_save_amount = "0";
    }
    var remain_val = parseInt(infostore.tempremainval);

    var i = parseInt(infostore.interest_rate);
    var n =
      (parseInt(infostore.child_need_age) - parseInt(infostore.child_age)) * 12;
    var e1 = Math.pow(1 + i / 400, n / 3);
    var d = Math.pow(1 + i / 400, -1 / 3);
    var remain_val = remain_val / (e1 - 1);
    var inevest = remain_val * (1 - d);
    if (inevest > 0 && !isNaN(inevest)) {
      while (1) {
        ++inevest;
        let m = (inevest * (e1 - 1)) / (1 - d);
        if (m > parseInt(infostore.tempremainval)) {
          m = m - parseInt(infostore.tempremainval);
          if (350 < m && parseInt(infostore.tempremainval) > 8000000) {
            ++inevest;
          }
          --inevest;
          break;
        }
      }
    }

    inevest = inevest - parseInt(monthly_save_amount);
    var nf = new Intl.NumberFormat("en-IN");
    infostore.addi_money = nf.format(parseInt(inevest));
    // infostore={...infostore,[e.target.id]:e.target.value}
    //infostore[e.target.id]=infostore[e.target.value];
    //  storeinfo(e);
  };
  const calculate_remaining = () => {
    var maturity = 0;
    //  if(e.target.id=="investor_type")
    //  {
    //     infostore.showhide[infostore.investor_type]={display:"none"};
    //     infostore.showhide[e.target.value]={display:""};
    //       var investor_type=e.target.value;
    //       var amt=infostore.save_money;
    //  }
    //  else if(e.target.id=="save_money")
    //  {
    //     var investor_type=infostore.investor_type;
    //     var amt=e.target.value;
    //  }
    //  else
    // console.log(infostore.save_money);
    // console.log(infostore.monthly_save_amount);

    var investor_type = infostore.investor_type;
    var amt = infostore.save_money;

    if (amt != "") {
      switch (investor_type) {
        case "Moderate":
          var rate = 8;
          break;
        case "Aggressive":
          var rate = 12;
          break;
        default:
          var rate = 5;
      }
      infostore.interest_rate = rate;
      maturity =
        parseInt(amt) *
        Math.pow(
          1 + rate / 100 / 4,
          4 *
          (parseInt(infostore.child_need_age) - parseInt(infostore.child_age))
        );
    }
    infostore.maturity = parseInt(maturity);
    let remain_val = parseInt(infostore.tempinflatedValue) - parseInt(maturity);
    remain_val = isNaN(remain_val) ? 0 : remain_val;
    let nf = new Intl.NumberFormat("en-IN");
    infostore.remain_val = nf.format(remain_val);
    infostore.tempremainval = remain_val;
    calloan_val();
    count_addi_money();
  };
  const validate = () => {
    var temp_return = true;
    infostore.error_message = "";

    // console.log(infostore);
    if (infostore.current_amount_require == "0") {
      infostore.error_message =
        infostore.error_message + "Please enter require amount";
      temp_return = false;
    }
    if (infostore.child_name == "") {
      infostore.error_message = "Please enter child name";
      temp_return = false;
    }
    if (infostore.father_name == "") {
      infostore.error_message = "Please enter father name ";
      temp_return = false;
    }

    return temp_return;
  };
  return (
    <div>
      <Tabs />
      <div className="custome-row">
        <div className="container pt-5 mt-5">
          <div class="path-text mt-3">
            {" "}
            <span style={{ cursor: 'pointer' }}
              onClick={() => {
                navigate("/");
              }}
            >
              Calculator
            </span>{" "}
            &gt; Child Education Calculator
          </div>
          <div class="mt-4 mb-3 fd-cal-text">Child Education Calculator</div>
          <div className="row ">
            <div className="col-lg-8 col-12">
              <div className="background p-5">
                <div class="accordion" id="ChildaccordionExample2">
                  <div class="accordion-item" >
                    <h2 class="accordion-header" id="ChildheadingOne">
                      <button
                        class="accordion-button collapsed "
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#ChildcollapseOne"
                        aria-expanded="false"
                        aria-controls="ChildcollapseOne"
                      >
                        About You
                      </button>
                    </h2>
                    <div
                      id="ChildcollapseOne"
                      class="accordion-collapse collapse "
                      aria-labelledby="ChildheadingOne"
                      data-bs-parent="#ChildaccordionExample2"
                    >
                      <div class="accordion-body">
                        <div className="row">
                          <div className="col-md-6 col-12">
                            <div className="same-heading">
                              First, lets talk about yourself
                            </div>
                            <div className="pt-3">
                              <label for="" class="form-label">
                                Name
                              </label>
                              <input
                                type="name"
                                class="form-control"
                                id="father_name"
                                aria-describedby="emailHelp"
                                onChange={(e) => {
                                  if (
                                    !strRegexAlfa.test(e.target.value) ||
                                    e.target.value.length > 50
                                  ) {
                                    e.target.value = "";
                                  }
                                  storeinfo(e);
                                }}
                              ></input>
                            </div>
                            <div class="pt-3">
                              <label for="Select" class="form-label">
                                Age
                              </label>
                              <select
                                id="father_age"
                                class="form-select"
                                onChange={storeinfo}
                              >
                                {age?.map((val, i) => (
                                  <option>{val}</option>
                                ))}
                              </select>
                            </div>
                            <div class="pt-3">
                              <label for="Select" class="form-label">
                                Gender
                              </label>
                              <select
                                id="father_gender"
                                class="form-select"
                                onChange={storeinfo}
                              >
                                <option>Male</option>
                                <option>Female</option>
                              </select>
                            </div>
                          </div>
                          <div className="col-md-6 col-12">
                            <div className="same-heading">And about your child</div>
                            <div className="pt-3">
                              <label for="" class="form-label">
                                Name
                              </label>
                              <input
                                type="name"
                                class="form-control"
                                id="child_name"
                                onChange={(e) => {
                                  if (
                                    !strRegexAlfa.test(e.target.value) ||
                                    e.target.value.length > 50
                                  ) {
                                    e.target.value = "";
                                  }
                                  storeinfo(e);
                                }}
                                aria-describedby="emailHelp"
                              ></input>
                            </div>
                            <div class="pt-3">
                              <label for="Select" class="form-label">
                                Age
                              </label>
                              <select
                                id="child_age"
                                class="form-select"
                                onChange={storeinfo}
                              >
                                {age1?.map((val, i) => (
                                  <option>{val}</option>
                                ))}
                              </select>
                            </div>
                            <div class="pt-3">
                              <label for="Select" class="form-label">
                                Gender
                              </label>
                              <select
                                id="child_gender"
                                class="form-select"
                                onChange={storeinfo}
                              >
                                <option>Male</option>
                                <option>Female</option>
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="accordion-item mt-5" id="ChildaccordionExample2">
                    <h2 class="accordion-header" id="ChildheadingTwo">
                      <button
                        class="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#ChildcollapseTwo"
                        aria-expanded="false"
                        aria-controls="ChildcollapseTwo"
                      >
                        Getting Started
                      </button>
                    </h2>
                    <div
                      id="ChildcollapseTwo"
                      class="accordion-collapse collapse"
                      aria-labelledby="ChildheadingTwo"
                      data-bs-parent="#ChildaccordionExample2"
                    >
                      <div class="accordion-body">
                        <div className="same-heading">
                          What do you want {infostore.child_name} to be
                        </div>
                        <div className="py-3">
                          {/* <Tabs> */}
                          <TabList>
                            <Tab
                              onClick={() => {
                                setInfo({ ...infostore, area: "A Professor" });
                              }}
                            >
                              <img
                                src={Professor}
                                style={{ width: "50px", marginLeft: "10px" }}
                              />
                              <div>A Professor</div>
                            </Tab>
                            <Tab
                              onClick={() => {
                                setInfo({ ...infostore, area: "A Media" });
                              }}
                            >
                              <img src={Media} style={{ width: "50px" }} />
                              <div>A Media</div>
                            </Tab>
                            <Tab
                              onClick={() => {
                                setInfo({ ...infostore, area: "A Scientist" });
                              }}
                            >
                              <img
                                src={Scientist}
                                style={{ width: "50px", marginLeft: 9 }}
                              />
                              <div>A Scientist</div>
                            </Tab>
                            <Tab
                              onClick={() => {
                                setInfo({ ...infostore, area: "A Doctor" });
                              }}
                            >
                              <img src={Doctor} style={{ width: "50px" }} />
                              <div>A Doctor</div>
                            </Tab>
                            <Tab
                              onClick={() => {
                                setInfo({ ...infostore, area: "A Engineer" });
                              }}
                            >
                              <img
                                src={Engineer}
                                style={{ width: "50px", marginLeft: 10 }}
                              />
                              <div>A Engineer</div>
                            </Tab>
                            <Tab
                              onClick={() => {
                                setInfo({ ...infostore, area: "A Pilot" });
                              }}
                            >
                              <img src={Pilot} style={{ width: "50px" }} />
                              <div style={{ marginLeft: 5 }}>A Pilot</div>
                            </Tab>
                            <Tab
                              onClick={() => {
                                setInfo({ ...infostore, area: "Child's Choice" });
                              }}
                            >
                              <img
                                src={Child}
                                style={{ width: "50px", marginLeft: 18 }}
                              />
                              <div>Child's Choice</div>
                            </Tab>
                          </TabList>
                          {/* </Tabs> */}
                        </div>
                        <div className="row">
                          <div className="col-md-6 col-12">
                            <div className="same-heading">
                              {infostore.child_name} would need money at an age
                            </div>
                            <div class="pt-3">
                              <label for="Select" class="form-label">
                                Age
                              </label>
                              <select
                                id="child_need_age"
                                class="form-select"
                                onChange={storeinfo}
                              >
                                {age2?.map((val, i) => (
                                  <option>{val}</option>
                                ))}
                              </select>
                            </div>
                          </div>
                          <div className="col-md-6 col-12">
                            <div className="same-heading">
                              Where do you want {infostore.child_name} to study
                            </div>
                            <div class="pt-3">
                              <label for="Select" class="form-label">
                                Location
                              </label>
                              <select
                                id="location"
                                class="form-select"
                                onChange={storeinfo}
                              >
                                <option>India</option>
                                <option>Abroad</option>
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="accordion-item mt-5" id="ChildaccordionExample2">
                    <h2 class="accordion-header" id="ChildheadingThree">
                      <button
                        class="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#ChildcollapseThree"
                        aria-expanded="false"
                        aria-controls="ChildcollapseThree"
                      >
                        Finance
                      </button>
                    </h2>
                    <div
                      id="ChildcollapseThree"
                      class="accordion-collapse collapse"
                      aria-labelledby="ChildheadingThree"
                      data-bs-parent="#ChildaccordionExample2"
                    >
                      <div class="accordion-body">
                        <div className="row">
                          <div className="col-md-6 col-12">
                            <div className="pt-3">
                              <label for="" class="form-label">
                                The amount required at current rates
                              </label>
                              <input
                                type="name"
                                class="form-control"
                                id="current_amount_require"
                                onChange={(e) => {
                                  if (!strRegexNume.test(e.target.value)) {
                                    e.target.value = "";
                                    infostore.current_amount_require = "0";
                                  } else if (e.target.value > 100000000) {
                                    infostore.current_amount_require = "100000000";
                                    e.target.value = 100000000
                                  } else {
                                    storeinfo(e);
                                    //inflationCalculator(e);
                                  }
                                }}
                                aria-describedby="emailHelp"
                              ></input>
                            </div>
                            <div className="pt-3">
                              <label for="" class="form-label">
                                How much have you saved for him
                              </label>
                              <input
                                type="name"
                                class="form-control"
                                id="save_money"
                                onChange={(e) => {
                                  if (!strRegexNume.test(e.target.value)) {
                                    e.target.value = "";
                                    infostore.save_money = "0";
                                  } else if (e.target.value > 100000000) {
                                    infostore.save_money = "100000000";
                                    e.target.value = 100000000
                                  } else {
                                    storeinfo(e);
                                    //calculate_remaining(e);
                                  }
                                }}
                                aria-describedby="emailHelp"
                              ></input>
                            </div>
                          </div>
                          <div className="col-md-6 col-12">
                            <div className="pt-3">
                              <label for="" class="form-label">
                                Amount you save each month for{" "}
                                {infostore.child_name}'s education
                              </label>
                              <input
                                type="name"
                                class="form-control"
                                id="monthly_save_amount"
                                onChange={(e) => {
                                  if (!strRegexNume.test(e.target.value)) {
                                    e.target.value = "";
                                    infostore.monthly_save_amount = "0";
                                  } else if (e.target.value > 100000000) {
                                    infostore.monthly_save_amount = "100000000";
                                    e.target.value = 100000000
                                  } else {
                                    storeinfo(e);
                                    //count_addi_money(e);
                                  }
                                }}
                                aria-describedby="emailHelp"
                              ></input>
                            </div>
                            <div class="pt-3">
                              <label for="Select" class="form-label">
                                What kind of investor are you
                              </label>
                              <select
                                id="investor_type"
                                onChange={(e) => {
                                  storeinfo(e); //calculate_remaining(e);
                                }}
                                class="form-select"
                              >
                                <option>Conservative </option>
                                <option>Moderate</option>
                                <option>Aggressive</option>
                              </select>
                              <div className="mx-auto">
                                <span
                                  id="finances-tooltip1 "
                                  style={infostore.showhide.Conservative}
                                >
                                  <span class="tooltip-triangle"></span>Average
                                  returns with lower risk
                                  <br />
                                  (e.g. PPF/NSC/Post office/Bank FD)
                                </span>
                                <span
                                  id="finances-tooltip2 "
                                  style={infostore.showhide.Moderate}
                                >
                                  <span class="tooltip-triangle"></span>Relatively
                                  balanced returns with limited risk <br />
                                  (e.g. Gilt funds/ Debt MF, Balanced MF)
                                </span>
                                <span
                                  id="finances-tooltip3 "
                                  style={infostore.showhide.Aggressive}
                                >
                                  <span class="tooltip-triangle"></span>Potentially
                                  higher returns with high risk <br />
                                  (e.g. Equity MF, Stocks &amp; securities)
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 mt-5">
                    <span
                      style={{ color: "red", fontSize: "5px !important;" }}
                      ref={error_message}
                    ></span>
                    <button
                      type="button"
                      onClick={() => {
                        if (validate()) {
                          inflationCalculator();
                          setState(!state);
                        } else {
                          infostore.tempinflatedValue = "0";
                          infostore.inflatedValue = "0";
                          infostore.remain_val = "0";
                          infostore.tempremainval = "0";
                          infostore.loan = "0";
                          infostore.addi_money = "0";
                          infostore.maturity = "0";
                          setState(!state);
                        }
                        error_message.current.innerHTML = infostore.error_message;
                      }}
                      class="btn btn-primary"
                    >
                      <strong>Calculate</strong>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-12" style={{ marginBottom: 0 }}>
              <div className="background py-5  px-4">
                <div className="cep5-text d-flex">
                  <div>
                    To fund{" "}
                    <span className="personName cep5-text-blue-bold">
                      {infostore.child_name}
                    </span>
                    's education to be{" "}
                    <span id="professionLabel" className="cep5-text-blue-bold">
                      {infostore.area}
                    </span>
                    , with inflation @{" "}
                    <span id="inflationRate">
                      {infostore.inflamation_rate ? infostore.inflamation_rate : 0}%
                    </span>
                  </div>
                  <div>
                    <select
                      className="cep5-avya-select"
                      id="inflamation_rate"
                      onChange={storeinfo}
                    >
                      <option value="4">4%</option>
                      <option value="5">5%</option>
                      <option value="6" selected="">
                        6%
                      </option>
                      <option value="7">7%</option>
                      <option value="8">8%</option>
                      <option value="9">9%</option>
                      <option value="10">10%</option>
                    </select>
                  </div>
                </div>
                <div className="cep5-text py-3">
                  A corpus of{" "}
                  <span className="cep5-text-blue-bold">
                    <span id="corpusAmt">{infostore.inflatedValue}</span>
                  </span>
                  <span className="cep5-text-orange-bold">*</span> is required when{" "}
                  <span className="personName cep5-text-blue-bold">
                    {infostore.child_name}
                  </span>{" "}
                  turns &nbsp;
                  <span className="cep5-text-blue-bold personAge">
                    {infostore.child_need_age}{" "}
                  </span>{" "}
                  years in age
                </div>
                <div className="cep5-text">
                  How much have you saved for{" "}
                  <span className="gender-amt-label">him</span>
                </div>
                <div className="d-flex astimate my-3">
                  <div className="saved-val px-3 py-2">
                    {infostore.maturity} Saved
                  </div>
                  <div className="remaining-val my-auto">
                    {infostore.remain_val} remaining{" "}
                  </div>
                </div>
                <div>
                  <ul className="cep5-list">
                    <li>
                      To achieve your target, you need additional monthly savings of
                      &nbsp;
                      <span className="cep5-text-blue-bold">
                        <i className="fa fa-inr" />
                        <span
                          className="personMonthlySaving"
                          id="additionalmonthlysavings"
                        >
                          {infostore.addi_money}
                        </span>
                      </span>
                      &nbsp; Rs. approximately.
                    </li>
                    <li className="mt-3">
                      When{" "}
                      <span className="personName cep5-text-blue-bold">
                        {infostore.child_name}
                      </span>{" "}
                      turns{" "}
                      <span className="personAge cep5-text-blue-bold">
                        {infostore.child_need_age}
                      </span>
                      , considering your savings, if you would go for an Education
                      Loan for remaining amount of{" "}
                      <span>
                        <i className="fa fa-inr cep5-text-blue-bold" />
                        <span
                          id="edu_loanRemainLoanAmt"
                          className="cep5-text-blue-bold"
                        >
                          {infostore.remain_val}
                        </span>
                      </span>
                      , you would end up paying &nbsp;
                      <span className="cep5-text-blue-bold">
                        <i className="fa fa-inr cep5-text-blue-bold" />
                        <span
                          id="edu_loanEndUpPayingAmt"
                          className="cep5-text-blue-bold"
                        >
                          {infostore.loan}
                        </span>
                      </span>{" "}
                      per month for 5 years to repay the loan (assuming 12% rate of
                      interest)
                    </li>
                  </ul>
                </div>

              </div>
            </div>
            <div className="row mt-4" style={{ paddingLeft: 12, paddingRight: 22, marginTop: 10 }}>
              <div className="col-lg-8 col-12 background" style={{ marginLeft: 10 }}>
                <div >
                  <div style={{ marginLeft: 10, marginTop: 10 }}>

                    <strong>FAQs</strong>
                  </div>
                </div>
                <div class="accordion mt-0 mb-5" id="ChaaccordionExample1">
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="ChaheadingOne">
                      <button
                        class="accordion-button collapsed px-1 shadow-none"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#ChacollapseOne"
                        aria-expanded="true"
                        aria-controls="ChacollapseOne"
                      >
                        &nbsp;&nbsp;Why do you need to plan for your child education?
                      </button>
                    </h2>
                    <div
                      id="ChacollapseOne"
                      class="accordion-collapse collapse"
                      aria-labelledby="ChaheadingOne"
                      data-bs-parent="#FDaccordionExample1"
                    >
                      <div class="accordion-body px-1">
                        <>
                          <p id="isPasted">
                            <strong>
                              Child education planning is vital as it:
                            </strong>
                            <li><strong>Ensures Financial Readiness:</strong> Planning helps financially prepare for educational expenses,
                              avoiding unexpected financial burdens.</li>
                            <li><strong>Minimizes Dependence on Loans:</strong> Reduces the need for student loans, preventing long-term
                              debt for both parents and students.</li>
                            <li><strong>Invests in Future Opportunities:</strong> Provides the means for higher education, expanding a
                              child's career options and skill development.</li>
                            <li><strong>Allows for Flexible Choices:</strong> Enables parents to choose the type of education that aligns with
                              a child's goals, whether it's private schools, out-of-state colleges, or specialized programs.</li>
                            <li><strong>Capitalizes on Compound Growth:</strong> Starting early allows investments to grow over time,
                              maximizing the potential funds available for education.</li>
                            <li><strong>Reduces Stress:</strong> Alleviates financial stress, allowing students to focus on their studies
                              without worrying about funding constraints.</li>
                            <li><strong>Sets and Achieves Goals:</strong> Establishes specific financial goals for education, providing a
                              roadmap to meet a child's educational aspirations.</li>
                              <br/>
                            <span>In summary, child education planning is a strategic and proactive approach to securing a child's
                              educational future, fostering opportunities, and minimizing financial stress.</span>
                          </p>
                        </>
                      </div>
                    </div>
                  </div>
                  <div class="accordion-item  pb-5 pb-md-0" id="ChaaccordionExample1">
                    <h2 class="accordion-header" id="ChaheadingTwo">
                      <button
                        class="accordion-button collapsed px-1 shadow-none"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#ChacollapseTwo"
                        aria-expanded="false"
                        aria-controls="ChacollapseTwo"
                      >
                        &nbsp; Products with these benefits.
                      </button>
                    </h2>
                    <div
                      id="ChacollapseTwo"
                      class="accordion-collapse collapse"
                      aria-labelledby="ChaheadingTwo"
                      data-bs-parent="#ChaaccordionExample1"
                    >
                      <div class="accordion-body px-1">
                        <p style={{ textAlign: "left" }} id="isPasted">
                          <strong>
                            Products with these benefits:
                          </strong>
                          <li><strong>Child Education Insurance Plans:</strong> Many insurance companies offer specialized child
                            education insurance plans designed to fund your child's education. These plans typically
                            provide a lump sum payout or periodic payouts to meet the educational expenses when
                            your child reaches the age for higher education. This ensures that there are funds available
                            when needed, even in your absence.</li>
                          <li><strong>Risk Mitigation:</strong> Life insurance policies can provide financial protection for your child's
                            future education. In the event of your untimely demise, the life insurance payout can serve
                            as a source of funding for their education, helping them avoid financial hardships.</li>
                          <li><strong>Savings and Investments:</strong> Some insurance-linked savings or investment plans can be used to
                            accumulate funds for your child's education. These plans allow you to save and invest while
                            also providing insurance coverage. Over time, the accumulated funds can be directed toward
                            educational expenses.</li>
                          <li><strong>Goal-Oriented Planning:</strong> Insurance-linked education plans encourage goal-oriented savings.
                            By specifying the purpose as your child's education, it helps maintain focus on this financial
                            objective and ensures that funds are allocated accordingly.</li>
                          <li><strong>Tax Benefits:</strong> Premiums paid for life insurance policies or child education plans may be
                            eligible for tax deductions or exemptions, providing additional financial relief. When
                            incorporating insurance into your child's education planning, consider the following: -</li>
                          <li style={{ marginLeft: 0 }}><strong>Assessing Coverage Needs:</strong> Calculate the estimated cost of your child's
                            education, including tuition fees, living expenses, and inflation. Ensure that the
                            insurance coverage or savings plan aligns with your financial goals.</li>
                          <li style={{ marginLeft: 0 }}><strong>Choosing the Right Policy:</strong> Evaluate various insurance options, such as term
                            insurance, endowment plans, or dedicated child education plans, based on your
                            specific requirements and risk tolerance.</li>
                          <li style={{ marginLeft: 0 }}><strong>Regular Review:</strong> Periodically review and adjust your insurance and investment
                            plans to ensure they remain in line with your child's educational needs and any
                            changes in your financial situation. It's essential to strike a balance between
                            insurance and Other savings or investment vehicles when planning for your
                            child's education. Consult with a financial advisor to create a comprehensive and
                            tailored education plan that integrates insurance effectively to secure your
                            child's educational future.</li>
                        </p>
                      </div>
                      {/* <div className="pb-5"></div> */}
                    </div>
                    {/* <div className="pb-5"></div> */}
                  </div>
                  <div class="accordion-item" id="ChaaccordionExample1">
                    <h2 class="accordion-header" id="ChaheadingFour1">
                      <button
                        class="accordion-button collapsed px-1 shadow-none"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#ChaheadingFour"
                        aria-expanded="false"
                        aria-controls="ChaheadingFour"
                      >
                        &nbsp; How to use Child Education Plan calculator?
                      </button>
                    </h2>
                    <div
                      id="ChaheadingFour"
                      class="accordion-collapse collapse"
                      aria-labelledby="ChaheadingFour1"
                      data-bs-parent="#ChaaccordionExample1"
                    >
                      <div class="accordion-body px-1">

                        <strong> About You:</strong><br />
                        Enter the Father and Child's Names.<br />
                        Select the Father and Child's Ages.<br />
                        Choose the Gender for both Father and Child.<br /> <br />
                        <strong>Getting Started:</strong><br />
                        Select the desired Course for your Child.<br />
                        Specify the Age at which your Child will require funds.<br />
                        Choose the Location of study.<br /><br />
                        <strong>Finance:</strong><br />
                        Enter the required amount based on current rates.<br />
                        Input the monthly amount saved for your child's education.<br />
                        Provide the current savings for your child.<br />
                        Define your investor profile.<br /><br />
                        <strong>Calculate:</strong><br />

                        <strong>1.</strong> Click the "Calculate" Button.<br />
                        <strong>Result:</strong><br /> On clicking Calculate Button the results will be shown on the right side of the
                        screen as Instructed below:<br /><br />
                        <strong>1.</strong> <strong>Funding Education:</strong> Plan to fund 's education in the selected course with a default
                        4% inflation rate (adjustable).<br />
                        <strong>2.</strong> <strong>Required Corpus:</strong> A total corpus of (Total Amount Needed)* is needed when your
                        child turns (Age at which money required).<br />
                        <strong>3.</strong> <strong>Savings Overview:</strong> Detailed view of your current savings, including the saved
                        amount with FD rates based on the investor type, and the remaining balance.<br />
                        <strong>4.</strong> <strong>Additional Savings:</strong> Projection of required monthly savings based on Reverse RD
                        rates linked to the investor type.<br />
                        <strong>5.</strong> <strong>Loan Scenario:</strong> If necessary, details of the option for an Education Loan, including
                        the monthly repayment amount for 5 years at a 12% interest rate.

                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="pb-5"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
