import React, { useEffect, useState } from "react";
import "./cagr.css"
import { Tooltip as ReactTooltip } from "react-tooltip";
import 'react-tooltip/dist/react-tooltip.css'
import Slider from 'react-rangeslider';
import 'react-rangeslider/lib/index.css';
import Chart from 'react-apexcharts'
import { useNavigate } from "react-router-dom";
import Tabs from "../../components/tabs/tabs";


export default function CAGRCalculator() {
    const navigate = useNavigate()
    const [initialValue, setInitialValue] = React.useState(1000);
    const [finalValue, setFinalValue] = React.useState(1000);
    const [duration, setDuration] = React.useState(1);
    const [initialFlag, setInitialFlag] = React.useState(false);
    const [initialErr, setInitialErr] = React.useState("");
    const [durationFlag, setDurationFlag] = React.useState(false);
    const [durationErr, setDurationErr] = React.useState("");
    const [finalFlag, setFinalFlag] = React.useState(false);
    const [finalErr, setFinalErr] = React.useState("");
    const [invsVal, setEnvVal] = React.useState("");
    const [returnVal, setReturnVal] = React.useState("");
    const [totalVal, setTotalVal] = React.useState("");
    const [data, setData] = useState({
        options: {
            chart: {
                type: 'donut',
            },
            labels: [],
            tooltip: {
                y: {
                    title: {
                        formatter(seriesName) {
                            return seriesName
                        }
                    }
                }
            },
            dataLabels: {
                dropShadow: {
                    blur: 3,
                    opacity: 0.8,
                }
            },
            colors: ['#0073BB', '#E05776'],
        },
        series: [],

    })

    const handleChangeComplete = () => {
    }

    useEffect(() => {
        if (initialValue < 1000) {
            setInitialFlag(true);
            setInitialErr("Minimum value allowed is 1000")
        } else if (initialValue > 10000000) {
            setInitialFlag(true);
            setInitialErr("Maximun value allowed is 10000000")
        } else {
            setInitialFlag(false);
            setInitialErr("")
        }
    }, [initialValue]);

    useEffect(() => {
        if (finalValue < 1000) {
            setFinalFlag(true);
            setFinalErr("Minimum value allowed is 1000")
        } else if (finalValue > 10000000) {
            setFinalFlag(true);
            setFinalErr("Maximun value allowed is 10000000")
        } else {
            setFinalFlag(false);
            setFinalErr("")
        }
    }, [finalValue]);

    useEffect(() => {
        if (duration < 1) {
            setDurationFlag(true);
            setDurationErr("Minimum value allowed is 1")
        } else if (duration > 50) {
            setDurationFlag(true);
            setDurationErr("Maximun value allowed is 50")
        } else {
            setDurationFlag(false);
            setDurationErr("")
        }
    }, [duration]);

    const handleCalculation = (initialValue1, finalValue1, duration) => {
        let initial = parseInt(initialValue1);
        let final = parseInt(finalValue1);
        let year = duration;
        let cagr = 0;
        if (initial>=1000 && final>=1000 && year>0) {
           console.log("divya");
                
                    cagr = ((final / initial) ** (1 / year) - 1) * 100;
                    cagr = parseFloat(cagr.toFixed(2));
                    cagr = Math.ceil(cagr * 100) / 100;
                    console.log(cagr);
                    let nf = new Intl.NumberFormat('en-IN');


                    setEnvVal(nf.format(initial?.toFixed(0)));
                    setTotalVal(nf.format(final?.toFixed(0)));
                    setReturnVal(nf.format(cagr.toFixed(2)));


                    let dt = data;
                    dt.options.labels[0] = "Final investment(₹) : " + " " + nf.format(final?.toFixed(0));
                    dt.options.labels[1] = "Initial investment(₹) : " + " " + nf.format(initial?.toFixed(0));
                    dt.series[0] = parseInt(finalValue)
                    dt.series[1] = parseInt(initialValue)
                    setData(dt);
                }
                
                else{
                    let nf = new Intl.NumberFormat('en-IN');
                    setEnvVal(0);
                    setReturnVal(0);
                    setTotalVal(0);
                    
                    let dt = data;
                    dt.options.labels[0] = "Final investment(₹) : " + " " + '₹' + " " + nf.format(0);
                    dt.options.labels[1] = "Initial investment(₹) : " + " " + '₹' + " " + nf.format(0);
                    dt.series[0] = 50
                    dt.series[1] = 50
                    setData(dt);
                }
            
        
        
    }


    useEffect(() => {
        handleCalculation(initialValue, finalValue, duration);
    }, [initialValue, finalValue, duration]);

    function Example(props) {
        return (
            <Chart className='donut-chart' options={data.options} series={data.series} type="donut" />
        );
    }

    return (
        <div>
<Tabs/>
        <div className="custome-row">
        <div className="container mt-5 pt-5">
            <div className='path-text'> <span className="ta-cursor" onClick={() => {
                navigate("/")
            }}>Calculator</span> &gt; CAGR Calculator</div>
            <div className='mt-4 fd-cal-text'>CAGR Calculator</div>
            <div className='row group-background'>
                <div className='col-lg-8 col-12 mt-4'>
                    <div className='background p-4' id="content-sip">
                        <div>
                            <div className='d-flex justify-content-between'>
                                <div>Initial Investment</div>
                                <div className="d-flex" style={{ width: 115 }}>
                                    <span>
                                        {initialFlag === true ? <svg data-tooltip-id="initialValue" style={{ color: '#E05776' }} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" height="18" width="18" class="cis93InfoIcon contentNegative"><path fill="none" d="M0 0h24v24H0z"></path><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-6h2v6zm0-8h-2V7h2v2z"></path></svg> : ""}
                                    </span>&nbsp;
                                    <ReactTooltip id="initialValue" place="top" content={initialErr} />
                                    <input style={{ background: initialFlag === true ? "rgba(187, 3, 0, 0.1)" : 'rgba(0, 115, 187, 0.10)', color: initialFlag === true ? "#E05776" : '' }} className={`form-control shadow-none sm-fl rd-max1`} id={`${initialFlag === true ? "fname1" : "fname"}`} value={"₹"} readOnly />
                                    <input onChange={e => {
                                        if (isNaN(e.target.value)) { } else if (e.target.value.includes(".") == true) { } else if (e.target.value.includes("+") == true) { } else if (e.target.value.includes("-") == true) { } else {
                                            if (e.target.value > 10000000) { setInitialValue(10000000); } else if (e.target.value == "") { setInitialValue(0); } else if (e.target.value == 0) { setInitialValue(0); } else {
                                                if (e.target.value.indexOf(0) == '0' && e.target.value.length > 1) {
                                                    let va = e.target.value.substring(1)
                                                    setInitialValue(va)
                                                } else if (initialValue == 0 && e.target.value?.split("")[1] == '0') {
                                                    let va = e.target.value?.split("")[0];
                                                    setInitialValue(va)
                                                } else {
                                                    setInitialValue(e.target.value)
                                                }
                                            }
                                        }
                                    }} value={initialValue} maxLength={10000000} style={{ background: initialFlag === true ? "rgba(187, 3, 0, 0.1)" : 'rgba(0, 115, 187, 0.10)', color: initialFlag === true ? "#E05776" : '', paddingLeft: 0, textAlign: "right" }} className={`shadow-none form-control sm-bg`} type="text" id={`${initialFlag === true ? "fname1" : "fname"}`} name="fname" />
                                </div>
                            </div>
                            <Slider
                                min={1000}
                                max={10000000}
                                value={initialValue}
                                onChange={(value) => {
                                    setInitialValue(value)
                                }}
                                onChangeComplete={handleChangeComplete}
                                step={1000}
                            />
                        </div>
                        <div>
                            <div className='d-flex justify-content-between'>
                                <div>Final Investment</div>
                                <div className="d-flex" style={{ width: 115 }}>
                                    <span>
                                        {finalFlag === true ? <svg data-tooltip-id="finalValue" style={{ color: '#E05776' }} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" height="18" width="18" class="cis93InfoIcon contentNegative"><path fill="none" d="M0 0h24v24H0z"></path><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-6h2v6zm0-8h-2V7h2v2z"></path></svg> : ""}
                                    </span>&nbsp;
                                    <ReactTooltip id="finalValue" place="top" content={finalErr} />
                                    <input style={{ background: finalFlag === true ? "rgba(187, 3, 0, 0.1)" : 'rgba(0, 115, 187, 0.10)', color: finalFlag === true ? "#E05776" : '' }} className={`form-control shadow-none sm-fl rd-max1`} id={`${finalFlag === true ? "fname1" : "fname"}`} value={"₹"} readOnly />
                                    <input onChange={e => {
                                        if (isNaN(e.target.value)) { } else if (e.target.value.includes(".") == true) { } else if (e.target.value.includes("+") == true) { } else if (e.target.value.includes("-") == true) { } else {
                                            if (e.target.value > 10000000) { setFinalValue(10000000); } else if (e.target.value == "") { setFinalValue(0); } else if (e.target.value == 0) { setFinalValue(0); } else {
                                                if (e.target.value.indexOf(0) == '0' && e.target.value.length > 1) {
                                                    let va = e.target.value.substring(1)
                                                    setFinalValue(va)
                                                } else if (finalValue == 0 && e.target.value?.split("")[1] == '0') {
                                                    let va = e.target.value?.split("")[0];
                                                    setFinalValue(va)
                                                } else {
                                                    setFinalValue(e.target.value)
                                                }
                                            }
                                        }
                                    }} value={finalValue} maxLength={10000000} style={{ background: finalFlag === true ? "rgba(187, 3, 0, 0.1)" : 'rgba(0, 115, 187, 0.10)', color: finalFlag === true ? "#E05776" : '', paddingLeft: 0, textAlign: "right" }} className={`shadow-none form-control sm-bg`} type="text" id={`${finalFlag === true ? "fname1" : "fname"}`} name="fname" />
                                </div>
                            </div>
                            <Slider
                                min={1000}
                                max={10000000}
                                value={finalValue}
                                onChange={(value) => {
                                    setFinalValue(value)
                                }}
                                onChangeComplete={handleChangeComplete}
                                step={1000}
                            />
                        </div>
                        <div>
                            <div className='d-flex justify-content-between'>

                                <div>Duration Of Investment</div>
                                <div className="d-flex" style={{ width: 115 }}>
                                    <span >
                                        {durationFlag === true ? <svg data-tooltip-id="duration" style={{ color: '#E05776' }} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" height="18" width="18" class="cis93InfoIcon contentNegative"><path fill="none" d="M0 0h24v24H0z"></path><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-6h2v6zm0-8h-2V7h2v2z"></path></svg> : ""}
                                    </span>&nbsp;
                                    <ReactTooltip id="duration" place="top" content={durationErr} />

                                    <input
                                        onChange={e => {
                                            if (isNaN(e.target.value)) { } else if (e.target.value.includes("+") == true) { } else if (e.target.value.includes("-") == true) { } else if (e.target.value.includes(".") == true) { } else {
                                                if (duration != "0" && e.target.value > 50) { setDuration(50) } else if (e.target.value == "") { setDuration(0); } else if (e.target.value == 0) { setDuration(0); } else {
                                                    if (e.target.value.indexOf(0) == '0' && e.target.value.length > 1) {
                                                        let va = e.target.value.substring(1)
                                                        setDuration(va)
                                                    } else if (duration == 0 && e.target.value?.split("")[1] == '0') {
                                                        let va = e.target.value?.split("")[0];
                                                        setDuration(va)
                                                    } else {
                                                        setDuration(e.target.value)
                                                    }
                                                }
                                            }

                                        }} value={duration} className='shadow-none form-control sm-bgr absright' style={{ color: durationFlag === true ? "#E05776" : '', paddingLeft: 0, textAlign: "right", paddingRight: 2 }} type="text" id={`${durationFlag === true ? "fname1" : "fname"}`} name="fname" />
                                    <label style={{ background: durationFlag === true ? "rgba(187, 3, 0, 0.1)" : 'rgba(0, 115, 187, 0.10)', color: durationFlag === true ? "#E05776" : '', marginLeft: 2 }} className='shadow-none form-control sm-fr wdthcls' htmlFor={`${durationFlag === true ? "fname1" : "fname"}`} id={`${durationFlag === true ? "fname1" : "fname"}`}>Yr</label>
                                </div>
                            </div>
                            <Slider
                                min={1}
                                max={50}
                                value={duration}
                                onChange={(value) => {
                                    setDuration(value)
                                }}
                                onChangeComplete={handleChangeComplete}
                            />
                        </div>
                        <div className='d-flex justify-content-between pb-4'>
                            <div className='sip-count-text mt-4'><b>Initial Investment</b></div>
                            <div className="mt-4"><b>₹ {initialValue}</b></div>
                        </div>
                        <div className='d-flex justify-content-between '>
                            <div className='sip-count-text'><b>Final Investment</b></div>
                            <div><b>₹ {finalValue}</b></div>

                        </div>
                        <div className='d-flex justify-content-between pt-3 pb-4 mb-1'>
                            <div className='sip-count-text'><b>CAGR Is</b></div>
                            <div><b>{returnVal} %</b></div>
                        </div>
                    </div>
                </div>
                <div className='col-lg-4 col-12 mt-4 mb-5 pb-5 mb-lg-0'>
                    <div className='background p-4 text-center'>
                        <div className="pt-4"></div>
                        <div className="d-flex justify-content-center">
                            <div className="d-flex justify-content-center">
                                <div className="circle-blue"></div>
                                <div className="circle-text">Final Investment</div>
                            </div>
                            <div className="d-flex justify-content-center mx-4 pb-3">
                                <div className="circle-pink"></div>
                                <div className="circle-text">Initial Investment</div>
                            </div>
                        </div>
                        {<Example />}
                        <div className="pb-5"></div>
                    </div>
                </div>
            </div>
        </div>
        </div>
        </div>
    )
}

