import React, { useEffect, useState } from "react";
import "./Health.css";
import "react-tooltip/dist/react-tooltip.css";
import "react-rangeslider/lib/index.css";
import { useNavigate } from "react-router-dom";
import Tabs from "../../components/tabs/tabs";
import City from "./city.json";
export default function Health() {
  const [cityValue, setCity] = useState("Zone-A");
  const [er, setEr] = useState("");
  const [nameEr, setNameEr] = useState("");
  const [emailEr, setEmailEr] = useState("");
  const [birthEr, setBirthEr] = useState("");
  const [name, setName] = useState("");
  const [eamil, setEmail] = useState("");
  const [dob, setDob] = useState("");
  const [number, setNumber] = useState("");
  const [age, setAge] = useState("");  
  const navigate = useNavigate();
  const onChageAge = (ev) => {
    setNameEr("")
    const getAge = Math.floor(
      (new Date() - new Date(ev.target.value).getTime()) / 3.15576e10
    );
    setAge(getAge)
    setDob(ev.target.value);
    let str = localStorage.getItem("state");
    let usr = JSON.parse(str);
    usr.age = ev.target.value;
    usr.policy=getAge
    localStorage.setItem("state", JSON.stringify(usr))
  };
  const cityChange = (e) => {
    setCity(e.target.value);
    let str = localStorage.getItem("state");
    let usr = JSON.parse(str);
    usr.cityValue = e.target.value?.split(',')[0];
    localStorage.setItem("state", JSON.stringify(usr))
  };


  const getCurrentDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    let month = today.getMonth() + 1;
    let day = today.getDate();
    month = month < 10 ? `0${month}` : month;
    day = day < 10 ? `0${day}` : day;
    return `${year}-${month}-${day}`;
  };

  const seePlan = () => {
    setEr("")
    setNameEr("")
    setEmailEr("")
    setBirthEr("")
    if (name == undefined && eamil == undefined && number == undefined) {
      setEr("Enter your name, mobile number and email");
      return false
    }
    if (name == undefined) {
      setNameEr("Please enter your name");
      return false
    }
    if (number == undefined) {
      setNameEr("Please enter your number");
      return false
    }
    if (eamil == undefined) {
      setEmailEr("Please enter your email");
      return false
    }
    if (number?.length < 10) {
      setNameEr("Mobile number should be 10 digits");
      return false
    }
    if (eamil && !eamil.match(
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    )) {
      setEmailEr("Please enter valid email");
      return false
    }
    if (age == undefined || age == NaN) {
      setNameEr("Please enter your date of birth");
      return false
    }
    if (age < 18) {
      setNameEr("Minimum age should be 18 years");
      return false
    }
    navigate("/medical-calculated")
  }

  useEffect(() => {
    if (localStorage.getItem("state")) {
      let info = localStorage.getItem("state");
      let usr=JSON.parse(info);
      setName(usr?.name);
      setEmail(usr?.email);
      setNumber(usr?.number);
      setDob(usr?.age);
      setCity(usr?.cityValue?.split(",")[0]);
      if(usr?.age!=""){
        const getAge = Math.floor(
          (new Date() - new Date(usr?.age).getTime()) / 3.15576e10
        );
        setAge(getAge);
      }
    } else {
      let initial = {
        name: '',
        email: "",
        age: '',
        age: "",
        cityValue: "Zone-A",
        number: "",
        policy:""
      }
      localStorage.setItem("state", JSON.stringify(initial));
    }
  }, [])

  return (
    <div>
      <Tabs />
      <div className="custome-row">
        <div className="container mt-5 pt-5">
          <div className="path-text mt-4" style={{ marginLeft: 14 }}>
            {" "}
            <span
              className="ta-cursor"
              onClick={() => {
                navigate("/");
              }}
            >
              Calculator
            </span>{" "}
            &gt;Medical Calculator
          </div>
          <div
            className="mt-0 fd-cal-text mt-4 pb-1"
            style={{ marginLeft: 14 }}
          >
            Medical Calculator
          </div>
          <div className="group-background">
            <div className="container-fluid pb-5">
              <div className="row m-5 pb-0">
                <div className="col-lg-3 col-12 mt-lg-5 ">
                  <label htmlFor="" className="form-label">
                    Name<sup style={{ color: "red" }}>*</sup>
                  </label>
                  <input
                    type="text"
                    value={name}
                    onChange={(e) => {
                      setEr("")
                      setName(e.target.value);
                      let str = localStorage.getItem("state");
                      let usr = JSON.parse(str);
                      usr.name = e.target.value;
                      localStorage.setItem("state", JSON.stringify(usr))
                    }}
                    placeholder="Enter Name..."
                    className="form-control shadow-none"
                  />
                </div>
                <div className="col-lg-3 col-12 mt-5">
                  <label htmlFor="" className="form-label">
                    Mobile Number<sup style={{ color: "red" }}>*</sup>
                  </label>
                  <input
                    type="text"
                    value={number}
                    onChange={(e) => {
                      setEr("")
                      if (isNaN(e.target.value)) {
                      } else if (e.target.value?.length > 10) {
                      } else {
                        setNumber(e.target.value);
                        let str = localStorage.getItem("state");
                        let usr = JSON.parse(str);
                        usr.number = e.target.value;
                        localStorage.setItem("state", JSON.stringify(usr))
                      }
                    }}
                    placeholder="Enter Mobile Number..."
                    className="form-control shadow-none"
                  />
                </div>
                <div className="col-lg-3 col-12 mt-5">
                  <label htmlFor="" className="form-label">
                    Email Id<sup style={{ color: "red" }}>*</sup>
                  </label>
                  <input
                    type="email"
                    value={eamil}
                    onChange={(e) => {
                      let sp = `!#$%^&*()_+-=|\{}[]:";'<>?,`;
                      let arOfsp = sp?.split("");
                      let ev = e.target.value?.split("");
                      setEr("");
                      if (arOfsp?.includes(ev[ev?.length - 1]) == true) {
                      } else {
                        let str = localStorage.getItem("state");
                        let usr = JSON.parse(str);
                        usr.email = e.target.value;
                        localStorage.setItem("state", JSON.stringify(usr))
                        setEmail(e.target.value);
                      }
                    }}
                    placeholder="Enter Email Id"
                    className="form-control shadow-none"
                  />
                </div>
                <div className="col-lg-3 col-12 mt-5">
                  <label htmlFor="" className="form-label">
                    Choose City<sup style={{ color: "red" }}>*</sup>
                  </label>
                  <select
                    onChange={cityChange}
                    value={cityValue}
                    className="form-control shadow-none"
                    style={{ height: 30, paddingTop: 4 }}
                  >
                    {City
                      ? City?.map((val, i) => (
                        <option
                          key={i}
                          value={val?.value + "," + val?.name}
                        >
                          {val?.name}
                        </option>
                      ))
                      : []}
                  </select>
                </div>
                <div className="row mt-4" >
                  <div className="col-lg-3 col-12">
                    <label htmlFor="" className="form-label">
                      Date of birth<sup style={{ color: "red" }}>*</sup>
                    </label>
                    <input
                      type="date"
                      onChange={(selectedOption) => {onChageAge(selectedOption)}}
                      value={dob}
                      className="form-control shadow-none"
                      id="input-date-health"
                      placeholder="dd-mm-yyyy"
                      aria-describedby="emailHelp"
                      min="1960-01-01" // Set the minimum date
                      max={getCurrentDate()}
                    />

                  </div>
                  <div className="col-lg-6 col-12 mt-5" style={{height:20}}>
                    {er ? <span style={{ color: 'red', fontSize: 12 }}>{er}</span> : nameEr ? <span style={{ color: 'red', fontSize: 12 }}>{nameEr}</span> : emailEr ? <span style={{ color: 'red', fontSize: 12 }}>{emailEr}</span> : birthEr ? <span style={{ color: 'red', fontSize: 12 }}>{birthEr}</span> : ''}
                  </div>

                </div>
                <div className="row justify-content-center">
                  <div className="col-lg-3 col-12 mt-4">
                    <div>
                      <button
                        onClick={seePlan}
                        className="btn btn-danger"
                      >
                        Calculate
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              
            </div>
            

          </div>
          <div className="pb-5"></div>
        </div>
      </div>
    
    </div>
  );
}
