import React, { useEffect, useState } from 'react'
import { useNavigate } from "react-router-dom";
import Cites from "../../../assets/cities.json";
import Tabs from '../../components/tabs/tabs';

export default function Term() {
  const navigate = useNavigate();
  const [liability, setLiability] = useState(0);
  const [futureGoal, setFutureGoal] = useState(0);
  const [name, setName] = useState("");
  const [birthDate, seBirthDate] = useState("");
  const [mobileNo, setMobileNo] = useState("");
  const [annualIncom, setAnnualIncome] = useState("3");
  const [genders, setGender] = useState("male");
  const [tobacco, setTobacco] = useState("yes");
  const [occupation, setOccupation] = useState("self-emp");
  const [education, setEducation] = useState("graduate");
  const [recCoverLife, setRecCoverLife] = useState("0");
  const [city, setCity] = useState("");
  const [errMsg, setErrMsg] = useState("");
  let nf = new Intl.NumberFormat('en-IN');

  useEffect(() => {
    // termCalculator()
  }, [liability, futureGoal])

  const termCalculator = () => {
    if (name == "") {
      setErrMsg("Please enter your name *")
      setTimeout(() => {
        setErrMsg("")
      }, 3000);
    } else
      if (birthDate == "") {
        setErrMsg("Please enter your Date Of Birth *")
        setTimeout(() => {
          setErrMsg("")
        }, 3000);
      } else if (mobileNo == "") {
        setErrMsg("Please enter mobile number *")
        setTimeout(() => {
          setErrMsg("")
        }, 3000);
      } else if (mobileNo?.length > 10 || mobileNo?.length < 10) {
        setErrMsg("Mobile number should be 10 digits *")
        setTimeout(() => {
          setErrMsg("")
        }, 3000);
      }
      else if (mobileNo == "") {
        setErrMsg("Please enter your Mobile Number *")
        setTimeout(() => {
          setErrMsg("")
        }, 3000);
      }
      else {
        let annualincome = annualIncom;
        let gender = "";
        gender = genders
        let tobaco = "";
        tobaco = tobacco
        let occu = "";
        occu = occupation;
        let edu = "";
        edu = education;
        let age = 0;
        let year = birthDate?.includes("-") == true ? birthDate?.split("-")[0] : birthDate;
        const date = new Date();
        date.setFullYear(date.getFullYear() - year);
        age = date.getFullYear();
        if (year < "1958" || year > "2005" && !isNaN(year)) {
          setErrMsg("Age must be between 18 and 65 years *");
          setTimeout(() => {
            setErrMsg("")
          }, 3000);
          return false
        }
        //========================================================
        // if (year < "1958" || year > "2005" && !isNaN(year)) {
        //   setErrMsg("Age must be between 18 and 65 years *");
        // }
        // else {
        //   setErrMsg();
        // }


        let lastage = age;
        let planage = lastage - age;
        let recondedlife_cover = 0;
        if (annualincome == "3") {

          if (gender === "male" || gender === "female" || gender === "trans" && tobaco === "no" || tobaco === "yes") {

            if (occu === "salaried" || occu === "self-emp" || occu === "housewife") {

              if (edu === "graduate") {
                recondedlife_cover = 2500000 + parseInt(liability) + parseInt(futureGoal);
                setRecCoverLife(recondedlife_cover)

              }
              else if (edu === "12th") {
                recondedlife_cover = 2500000 + parseInt(liability) + parseInt(futureGoal);;
                setRecCoverLife(recondedlife_cover)
              }
              else if (edu === "10th") {

                recondedlife_cover = 500000 + parseInt(liability) + parseInt(futureGoal);;
                setRecCoverLife(recondedlife_cover)
              }
            }
          }
        }
        else if (annualincome === '3-5') {
          if (gender === "male" || gender === "trans" || gender === "female" && tobaco === "no") {

            if (lastage < 39 && lastage >= 35) {
              if (occu === "salaried") {

                if (edu === "graduate" || edu === "12th") {
                  recondedlife_cover = 2500000 + parseInt(liability) + parseInt(futureGoal);;
                  setRecCoverLife(recondedlife_cover)
                  console.log("25 Lakhs accident cover")
                }
                else if (edu === "10th") {
                  recondedlife_cover = 1500000 + parseInt(liability) + parseInt(futureGoal);;
                  setRecCoverLife(recondedlife_cover)
                }
              }

              else if (occu === "self-emp") {
                if (edu === "graduate" || edu === "12th") {
                  recondedlife_cover = 5000000 + parseInt(liability) + parseInt(futureGoal);;
                  setRecCoverLife(recondedlife_cover)
                }
                else if (edu === "10th") {
                  recondedlife_cover = 1500000 + parseInt(liability) + parseInt(futureGoal);;
                  setRecCoverLife(recondedlife_cover)
                }
              }
              else if (occu === "housewife") {
                if (edu === "graduate") {
                  recondedlife_cover = 5000000 + parseInt(liability) + parseInt(futureGoal);;
                  setRecCoverLife(recondedlife_cover)
                }
                else if (edu === "12th") {
                  recondedlife_cover = 2500000 + parseInt(liability) + parseInt(futureGoal);;
                  setRecCoverLife(recondedlife_cover)
                }
                else if (edu === "10th") {
                  recondedlife_cover = 1500000 + parseInt(liability) + parseInt(futureGoal);;
                  setRecCoverLife(recondedlife_cover)
                }
              }
            }
            else if (lastage < 35) {

              if (occu === "salaried") {

                if (edu === "graduate" || edu === "12th") {
                  recondedlife_cover = 5000000 + parseInt(liability) + parseInt(futureGoal);;
                  setRecCoverLife(recondedlife_cover)
                }
                else if (edu === "10th") {
                  recondedlife_cover = 1500000 + parseInt(liability) + parseInt(futureGoal);;
                  setRecCoverLife(recondedlife_cover)
                }
              }

              else if (occu === "self-emp") {
                if (edu === "graduate" || edu === "12th") {
                  recondedlife_cover = 5000000 + parseInt(liability) + parseInt(futureGoal);;
                  setRecCoverLife(recondedlife_cover)
                }
                else if (edu === "10th") {
                  recondedlife_cover = 1500000 + parseInt(liability) + parseInt(futureGoal);;
                  setRecCoverLife(recondedlife_cover)
                }
              }
              else if (occu === "housewife") {
                if (edu === "graduate") {
                  recondedlife_cover = 5000000 + parseInt(liability) + parseInt(futureGoal);;
                  setRecCoverLife(recondedlife_cover)
                }
                else if (edu === "12th") {
                  recondedlife_cover = 2500000 + parseInt(liability) + parseInt(futureGoal);;
                  setRecCoverLife(recondedlife_cover)
                }
                else if (edu === "10th") {
                  recondedlife_cover = 1500000 + parseInt(liability) + parseInt(futureGoal);;
                  setRecCoverLife(recondedlife_cover)
                }
              }
            }
          }

          else if (gender === "male" || gender === "tans" || gender === "female" && tobaco === "yes") {
            if (lastage < 39 && lastage >= 36) {
              if (occu === "salaried") {
                if (edu === "graduate" && edu === "12th") {
                  recondedlife_cover = 2500000 + parseInt(liability) + parseInt(futureGoal);;
                  setRecCoverLife(recondedlife_cover)
                  console.log("25 Lakhs accident cover")
                }
                else if (edu === "10th") {
                  recondedlife_cover = 1500000 + parseInt(liability) + parseInt(futureGoal);;
                  setRecCoverLife(recondedlife_cover)
                }
              }
              else if (occu === "self-emp") {
                if (edu === "graduate" || edu === "12th") {
                  recondedlife_cover = 5000000 + parseInt(liability) + parseInt(futureGoal);;
                  setRecCoverLife(recondedlife_cover)
                }
                else if (edu === "10th") {
                  recondedlife_cover = 1500000 + parseInt(liability) + parseInt(futureGoal);;
                  setRecCoverLife(recondedlife_cover)
                }
              }
              else if (occu === "housewife") {
                if (edu === "graduate") {
                  recondedlife_cover = 5000000 + parseInt(liability) + parseInt(futureGoal);;
                  setRecCoverLife(recondedlife_cover)
                }
                else if (edu === "12th") {
                  recondedlife_cover = 2500000 + parseInt(liability) + parseInt(futureGoal);;
                  setRecCoverLife(recondedlife_cover)
                }
                else if (edu === "10th") {
                  recondedlife_cover = 1500000 + parseInt(liability) + parseInt(futureGoal);;
                  setRecCoverLife(recondedlife_cover)
                }
              }
            }
            else if (lastage < 36) {
              if (occu === "salaried") {
                if (edu === "graduate") {
                  recondedlife_cover = 5000000 + parseInt(liability) + parseInt(futureGoal);;
                  setRecCoverLife(recondedlife_cover)

                }
                else if (edu === "12th") {
                  recondedlife_cover = 5000000 + parseInt(liability) + parseInt(futureGoal);;
                  setRecCoverLife(recondedlife_cover)
                  console.log("50 Lakhs accident cover")
                }
                else if (edu === "10th") {
                  recondedlife_cover = 1500000 + parseInt(liability) + parseInt(futureGoal);;
                  setRecCoverLife(recondedlife_cover)
                }
              }
              else if (occu === "self-emp") {
                if (edu === "graduate" || edu === "12th") {
                  recondedlife_cover = 5000000 + parseInt(liability) + parseInt(futureGoal);;
                  setRecCoverLife(recondedlife_cover)
                }
                else if (edu === "10th") {
                  recondedlife_cover = 1500000 + parseInt(liability) + parseInt(futureGoal);;
                  setRecCoverLife(recondedlife_cover)
                }
              }
              else if (occu === "housewife") {
                if (edu === "graduate") {
                  recondedlife_cover = 5000000 + parseInt(liability) + parseInt(futureGoal);;
                  setRecCoverLife(recondedlife_cover)
                }
                else if (edu === "12th") {
                  recondedlife_cover = 2500000 + parseInt(liability) + parseInt(futureGoal);;
                  setRecCoverLife(recondedlife_cover)
                }
                else if (edu === "10th") {
                  recondedlife_cover = 1500000 + parseInt(liability) + parseInt(futureGoal);;
                  setRecCoverLife(recondedlife_cover)
                }
              }
            }
          }

        }
        else if (annualincome === '5-7') {
          if (gender === "male" || gender === "trans" || gender === "female" && tobaco === "no" || tobaco === "yes") {
            if (lastage <= 65 && lastage > 60) {
              if (occu === "salaried" || occu === "self-emp" || occu === "housewife") {
                if (edu === "graduate" || edu === "12th") {
                  recondedlife_cover = 2500000 + parseInt(liability) + parseInt(futureGoal);;
                  setRecCoverLife(recondedlife_cover)
                }
                else if (edu === "10th") {
                  recondedlife_cover = 500000 + parseInt(liability) + parseInt(futureGoal);;
                  setRecCoverLife(recondedlife_cover)
                }
              }
            }
            else if (lastage <= 60 && lastage > 40) {
              if (occu === "salaried" || occu === "self-emp" || occu === "housewife") {
                if (edu === "graduate" || edu === "12th") {
                  recondedlife_cover = 5000000 + parseInt(liability) + parseInt(futureGoal);;
                  setRecCoverLife(recondedlife_cover)
                }
                else if (edu === "10th") {
                  recondedlife_cover = 500000 + parseInt(liability) + parseInt(futureGoal);;
                  setRecCoverLife(recondedlife_cover)
                }
              }
            }
            else if (lastage <= 40) {
              if (occu === "salaried" || occu === "self-emp") {
                if (edu === "graduate" || edu === "12th") {
                  recondedlife_cover = 10000000 + parseInt(liability) + parseInt(futureGoal);;
                  setRecCoverLife(recondedlife_cover)
                }
                else if (edu === "10th") {
                  recondedlife_cover = 500000 + parseInt(liability) + parseInt(futureGoal);;
                  setRecCoverLife(recondedlife_cover)
                }
              }
              else if (occu === "housewife") {
                if (edu === "graduate") {
                  recondedlife_cover = 5000000 + parseInt(liability) + parseInt(futureGoal);;
                  setRecCoverLife(recondedlife_cover)
                }
                else if (edu === "12th") {
                  recondedlife_cover = 2500000 + parseInt(liability) + parseInt(futureGoal);;
                  setRecCoverLife(recondedlife_cover)
                }
                else if (edu === "10th") {
                  recondedlife_cover = 500000 + parseInt(liability) + parseInt(futureGoal);;
                  setRecCoverLife(recondedlife_cover)
                }
              }
            }
          }
        }
        else if (annualincome === '7-10') {
          if (gender === "male" || gender === "trans" || gender === "female" && tobaco === "no" || tobaco === "yes") {
            if (lastage <= 65 && lastage > 60) {
              if (occu === "salaried" || occu === "self-emp" || occu === "housewife") {
                if (edu === "graduate" || edu === "12th") {
                  recondedlife_cover = 2500000 + parseInt(liability) + parseInt(futureGoal);;
                  setRecCoverLife(recondedlife_cover)
                }
                else if (edu === "10th") {
                  recondedlife_cover = 500000 + parseInt(liability) + parseInt(futureGoal);;
                  setRecCoverLife(recondedlife_cover)
                }
              }
            }
            else if (lastage <= 60 && lastage > 45) {
              if (occu === "salaried" || occu === "self-emp") {
                if (edu === "graduate" || edu === "12th") {
                  recondedlife_cover = "7500000";
                  setRecCoverLife(recondedlife_cover)
                }
                else if (edu === "10th") {
                  recondedlife_cover = 500000 + parseInt(liability) + parseInt(futureGoal);;
                  setRecCoverLife(recondedlife_cover)
                }
              }
              else if (occu === "housewife") {

                if (edu === "graduate" || edu === "12th") {
                  recondedlife_cover = 2500000 + parseInt(liability) + parseInt(futureGoal);;
                  setRecCoverLife(recondedlife_cover)
                }
                else if (edu === "10th") {
                  recondedlife_cover = 500000 + parseInt(liability) + parseInt(futureGoal);;
                  setRecCoverLife(recondedlife_cover)
                }
              }
            }
            else if (lastage <= 45) {
              if (occu === "salaried" || occu === "self-emp") {
                if (edu === "graduate" || edu === "12th") {
                  recondedlife_cover = 10000000 + parseInt(liability) + parseInt(futureGoal);;
                  setRecCoverLife(recondedlife_cover)
                }
                else if (edu === "10th") {
                  recondedlife_cover = 500000 + parseInt(liability) + parseInt(futureGoal);;
                  setRecCoverLife(recondedlife_cover)
                }
              }
              else if (occu === "housewife") {

                if (edu === "graduate") {
                  recondedlife_cover = 5000000 + parseInt(liability) + parseInt(futureGoal);;
                  setRecCoverLife(recondedlife_cover)
                }
                else if (edu === "12th") {
                  recondedlife_cover = 2500000 + parseInt(liability) + parseInt(futureGoal);;
                  setRecCoverLife(recondedlife_cover)
                }
                else if (edu === "10th") {
                  recondedlife_cover = 500000 + parseInt(liability) + parseInt(futureGoal);;
                  setRecCoverLife(recondedlife_cover)
                }
              }
            }
          }
        }

        else if (annualincome === "10") {
          if (gender === "male" || gender === "trans" || gender === "female" && tobaco === "no" || tobaco === "yes") {
            if (lastage >= 61 && lastage <= 65) {

              if (occu === "salaried" || occu === "self-emp" || occu === "housewife") {
                if (edu === "graduate" || edu === "12th" || edu === "10th") {
                  recondedlife_cover = 2500000 + parseInt(liability) + parseInt(futureGoal);;
                  setRecCoverLife(recondedlife_cover)
                }
              }
            }
            else if (lastage <= 60) {

              if (occu === "salaried" || occu === "self-emp") {
                if (edu === "graduate" || edu === "12th" || edu === "10th") {
                  recondedlife_cover = 10000000 + parseInt(liability) + parseInt(futureGoal);;
                  setRecCoverLife(recondedlife_cover)
                }
              }
              else if (occu === "housewife") {
                if (edu === "graduate") {
                  recondedlife_cover = 10000000 + parseInt(liability) + parseInt(futureGoal);;
                  setRecCoverLife(recondedlife_cover)
                }
                else if (edu === "12th") {
                  recondedlife_cover = 2500000 + parseInt(liability) + parseInt(futureGoal);;
                  setRecCoverLife(recondedlife_cover)
                }
                else if (edu === "10th") {
                  recondedlife_cover = 500000 + parseInt(liability) + parseInt(futureGoal);;
                  setRecCoverLife(recondedlife_cover)
                }
              }
            }
          }
        }
      }
  }


  return (
    <div>
      <Tabs />
      <div className='custome-row'>
        <div className="container mt-5 pt-5">
          <div class="path-text mt-3">
            <span className="ta-cursor" onClick={() => {
              navigate("/")
            }}>Calculator</span> &gt; Term Insurance Calculator
          </div>
          <div class="mt-4 mb-3 fd-cal-text">Term Insurance Calculator</div>
          <div className='row '>
            <div className='col-lg-8 col-12 mb-3 mb-lg-0'>
              <div className='background p-5'>
                <div className='row'>
                  <div className='col-md-6 col-12'>
                    <div className="pt-3">
                      <label htmlFor="" className="form-label">Full Name<sup style={{ color: "red" }}>*</sup></label><br />
                      <input type="text" onChange={(e) => {
                        let strcheck = new RegExp(/^[a-zA-Z ]*$/);

                        if (!strcheck.test(e.target.value)) {
                          setErrMsg("Invalid Input *");
                        }
                        else {
                          setErrMsg();
                          setName(e.target.value);
                        }


                      }} value={name} className="form-control shadow-none" id="exampleInputEmail1" aria-describedby="emailHelp" />
                    </div>
                  </div>
                  <div className='col-md-6 col-12'>
                    <div className="pt-3">
                      <label htmlFor="" className="form-label">Date Of Birth<sup style={{ color: "red" }}>*</sup></label>
                      <input type="date" onChange={(e) => { seBirthDate(e.target.value) }} value={birthDate} className="form-control shadow-none" id="exampleInputEmail1" placeholder='dd-mm-yyyy' aria-describedby="emailHelp" min="1900-01-01"   // Set the minimum date
                        max="2099-12-31" />
                    </div>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-md-6 col-12'>
                    <div className="pt-3">
                      <label htmlFor="" className="form-label">Mobile<sup style={{ color: "red" }}>*</sup></label>
                      <input type="text" onChange={(e) => {
                        if (isNaN(e.target.value)) { } else if (e.target.value.includes("+") === true) { } else if (e.target.value.includes("-") === true) { } else if (e.target.value.includes(".") === true) { }
                        else if (e.target.value?.length > 10) {
                        } else {
                          setMobileNo(e.target.value);
                        }
                      }} value={mobileNo} className="form-control shadow-none" id="exampleInputEmail1" aria-describedby="emailHelp" />
                    </div>
                  </div>
                  <div className='col-md-6 col-12'>
                    <div class="pt-3">
                      <label for="Select" class="form-label"><strong>Annual Income (In Lakhs)</strong></label>
                      <select onChange={(e) => { setAnnualIncome(e.target.value) }} value={annualIncom} id="Select" class="form-select">
                        <option value="3" >3</option>
                        <option value="3-5">3 - 5</option>
                        <option value="5-7">5 - 7</option>
                        <option value="7-10">7 - 10</option>
                        <option value="10">10</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div className='col-md-12 col-12'>
                  <div class="pt-3">
                    <label for="Select" class="form-label"><strong>Choose your city</strong></label>
                    <select onChange={(e) => { setCity(e.target.value) }} value={city} id="Select" className="form-select">
                      {Cites?.map(val => <option>{val?.name}</option>)}
                    </select>

                  </div>
                </div>
                <div className='row'>
                  <div className='col-md-6 col-12'>
                    <div class="pt-3">
                      <label for="Select" class="form-label"><strong>Gender</strong></label>
                      <select value={genders} onChange={(e) => { setGender(e.target.value) }} id="Select" class="form-select">
                        <option value="male" >Male</option>
                        <option value="female">Female</option>
                        <option value="trans">Transgender</option>
                      </select>
                    </div>
                  </div>
                  <div className='col-md-6 col-12'>
                    <div class="pt-3">
                      <label for="Select" class="form-label"><strong>Tobacco / Nicotine</strong></label>
                      <select value={tobacco} onChange={(e) => { setTobacco(e.target.value) }} id="Select" class="form-select">
                        <option value="yes">Yes</option>
                        <option value="no">No</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-md-6 col-12'>
                    <div class="pt-3">
                      <label for="occupation" class="form-label"><strong>What's your occupation</strong></label>
                      <select value={occupation} onChange={(e) => { setOccupation(e.target.value) }} id="occupation" class="form-select">
                        <option value="">Select Occupation</option>
                        {genders === 'female' ? (
                          <React.Fragment>
                            <option value="salaried">Salaried</option>
                            <option value="self-emp">Self Employed / Business</option>
                            <option value="housewife">House Wife</option>
                          </React.Fragment>
                        ) : (
                          <React.Fragment>
                            <option value="salaried">Salaried</option>
                            <option value="self-emp">Self Employed / Business</option>
                          </React.Fragment>
                        )}
                      </select>
                    </div>
                  </div>
                  <div className='col-md-6 col-12'>
                    <div class="pt-3">
                      <label for="Select" class="form-label"><strong>Whats your Education?</strong></label>
                      <select value={education} onChange={(e) => { setEducation(e.target.value) }} id="Select" class="form-select">
                        <option value="graduate" selected>Graduate & Above</option>
                        <option value="12th">12th Pass</option>
                        <option value="10th">10th Pass</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-md-6 col-12'>
                    <div className="pt-3">
                      <label htmlFor="" className="form-label">Liability(₹)<sup style={{ color: "red" }}>*</sup></label>
                      <input type="text" value={liability}
                        onChange={e => {
                          if (isNaN(e.target.value)) { } else if (e.target.value.includes("+") == true) { } else if (e.target.value > 100000000) { setLiability(100000000) } else if (e.target.value.includes("-") == true) { } else if (e.target.value.includes(".") == true) { } else {
                            if (e.target.value == "") { setLiability(0); } else if (e.target.value == 0) { setLiability(0); } else {
                              if (e.target.value.indexOf(0) == '0' && e.target.value.length > 1) {
                                let va = e.target.value.substring(1)
                                setLiability(va)
                              } else if (liability == 0 && e.target.value?.split("")[1] == '0') {
                                let va = e.target.value?.split("")[0];
                                setLiability(va)
                              } else {
                                setLiability(e.target.value)
                              }
                            }
                          }

                        }}
                        className="form-control shadow-none" id="exampleInputEmail1" aria-describedby="emailHelp" />
                      <sub style={{ marginLeft: 5, color: '#c7c0c0' }}>Example : home loan, personal loan etc.</sub>
                    </div>
                  </div>
                  <div className='col-md-6 col-12'>
                    <div className="pt-3">
                      <label htmlFor="" className="form-label">Future Goal(₹)<sup style={{ color: "red" }}>*</sup></label>

                      <input type="text" value={futureGoal}
                        onChange={e => {
                          if (isNaN(e.target.value)) { } else if (e.target.value.includes("+") == true) { } else if (e.target.value > 100000000) { setFutureGoal(100000000) } else if (e.target.value.includes("-") == true) { } else if (e.target.value.includes(".") == true) { } else {
                            if (e.target.value == "") { setFutureGoal(0); } else if (e.target.value == 0) { setFutureGoal(0); } else {
                              if (e.target.value.indexOf(0) == '0' && e.target.value.length > 1) {
                                let va = e.target.value.substring(1)
                                setFutureGoal(va)
                              } else if (futureGoal == 0 && e.target.value?.split("")[1] == '0') {
                                let va = e.target.value?.split("")[0];
                                setFutureGoal(va)
                              } else {
                                setFutureGoal(e.target.value)
                              }
                            }
                          }

                        }}
                        className="form-control shadow-none" id="exampleInputEmail1" placeholder='' />
                      <sub style={{ marginLeft: 5, color: '#c7c0c0' }}>Example : child education, start a new business etc.</sub>
                    </div>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-12 mt-5'>
                    <div className='pb-2'>
                      <span className='err-msg'>{errMsg ? errMsg : ""}</span>
                    </div>
                    <button type="button" onClick={termCalculator} class="btn btn-primary"><strong>Calculate</strong></button>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-lg-4 col-12 result-term'>
              <div className='background p-5'>
                <div className='row'>
                  <div className='col-7'>
                    <div className='cep5-text-blue-bold'><strong>Recommended Life Cover</strong></div>
                  </div>
                  <div className='col-5'>
                    <div class="bg-pink py-1">₹ {nf.format(recCoverLife)}</div>
                  </div>
                </div>
                {/* <div className='row mt-4'>
                            <div className='col-12'>
                                <div className='cep5-text-blue-bold'><strong>Cover Yourself Till</strong></div>
                                <select value={coverLife} onChange={(e) => { setCoverLife(e.target.value) }}  id="Select" className="form-select mt-3">
                                    <option value='60'>60 Years || 356 Rs.</option>
                                    <option value='62'>62 Years || 359 Rs.</option>
                                </select>
                            </div>
                        </div> */}

              </div>
            </div>
            <div className='row mt-3' style={{ paddingLeft: 22 }}>
              <div className="col-lg-8 col-12 background" style={{ marginLeft: 0, marginTop: 20 }}>
                <div >
                  <div style={{ marginLeft: 10, marginTop: 10 }}>

                    <strong>FAQs</strong>
                  </div>
                </div>
                <div class="accordion mt-0 mb-5" id="TRaccordionExample1">
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="TRheadingOne">
                      <button
                        class="accordion-button collapsed px-1 shadow-none"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#TRcollapseOne"
                        aria-expanded="true"
                        aria-controls="TRcollapseOne"
                      >
                        &nbsp;&nbsp;What is Term insurance?
                      </button>
                    </h2>
                    <div
                      id="TRcollapseOne"
                      class="accordion-collapse collapse"
                      aria-labelledby="TRheadingOne"
                      data-bs-parent="#TRaccordionExample1"
                    >
                      <div class="accordion-body px-1">
                        <>
                          <p id="isPasted">
                            Term insurance is a type of life insurance that provides coverage for a specified term or period,
                            typically ranging from 5 to 30 years.
                          </p>
                        </>
                      </div>
                    </div>
                  </div>
                  <div class="accordion-item  pb-5 pb-md-0" id="TRaccordionExample1">
                    <h2 class="accordion-header" id="TRheadingTwo">
                      <button
                        class="accordion-button collapsed px-1 shadow-none"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#TRcollapseTwo"
                        aria-expanded="false"
                        aria-controls="TRcollapseTwo"
                      >
                        &nbsp; Why do you need term Insurance?
                      </button>
                    </h2>
                    <div
                      id="TRcollapseTwo"
                      class="accordion-collapse collapse"
                      aria-labelledby="TRheadingTwo"
                      data-bs-parent="#TRaccordionExample1"
                    >
                      <div class="accordion-body px-1">
                        <p style={{ textAlign: "left" }} id="isPasted">
                          Term insurance is a type of life insurance that provides coverage for a specified term or period,
                          typically ranging from 5 to 30 years. It is one of the simplest and most affordable forms of life
                          insurance. Importance of Term Insurance:
                          <br /><br />1.<strong> Financial Security for Dependents:</strong> Term insurance provides financial protection for your dependents in case of your untimely death. It ensures that they have a financial safety net to cover expenses like daily living, debts, education, and future goals.
                          <br /><br /> 2. <strong>Affordable Premiums:</strong> Term insurance is usually much more affordable than other types of life
                          insurance, making it accessible for individuals with various budget levels.
                          <br /><br /> 3. <strong>Focused on Protection:</strong> Term insurance is designed primarily for risk protection. It serves its
                          purpose by providing a high coverage amount (sum assured) at a relatively low cost.
                          <br /><br />4.<strong> Customizable Coverage:</strong> You can choose the coverage amount and policy term based on your
                          specific needs and financial obligations. This customization allows you to tailor the policy to your
                          family's requirements.

                          <br /><br /> 5. <strong>Risk Mitigation:</strong> Term insurance helps mitigate the financial risks associated with unexpected
                          events, such as premature death. It ensures that your loved ones are not burdened with financial
                          difficulties during an already challenging time.
                          <br /><br />6. <strong>Temporary Financial Obligations:</strong> Term insurance is particularly useful when you have temporary
                          financial obligations like a mortgage, outstanding loans, or children's education expenses. It can
                          cover these obligations during the policy term.
                          <br /><br />7. <strong>Investment Flexibility:</strong> By opting for term insurance, you can separate your insurance and
                          investment needs. You can invest separately in instruments that provide better returns without
                          compromising your family's financial security. In summary, term insurance is important because it
                          offers straightforward, cost-effective, and reliable financial protection for your loved ones. It ensures
                          that your family's financial well-being is secure, even if you're not there to provide for them. It's a
                          fundamental component of financial planning, especially if you have dependents who rely on your
                          income.
                        </p>

                      </div>
                      {/* <div className="pb-5"></div> */}
                    </div>
                    {/* <div className="pb-5"></div> */}
                  </div>
                  <div class="accordion-item mb-5 pb-5 pb-md-0" id="TRaccordionExample1">
                    <h2 class="accordion-header" id="TRheadingTwo">
                      <button
                        class="accordion-button collapsed px-1 shadow-none"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#TRcollapseFour"
                        aria-expanded="false"
                        aria-controls="TRcollapseFour"
                      >
                        &nbsp; How to use the term Insurance Calculator?
                      </button>
                    </h2>
                    <div
                      id="TRcollapseFour"
                      class="accordion-collapse collapse"
                      aria-labelledby="TRheadingFour"
                      data-bs-parent="#TRaccordionExample1"
                    >
                      <div class="accordion-body px-1">
                        <strong>Understanding the Term Insurance Calculator</strong><br /><br />
                        <strong>Step 1: Candidate Details</strong> <br />
                        <li><strong>Name:</strong> Enter your full name for identification.</li>
                        <li><strong>DOB (Date of Birth):</strong> Provide your date of birth for age calculation.</li>
                        <li><strong>Mobile Number:</strong> Include your mobile number for communication purposes.</li><br />
                        <strong>Step 2: Financial Information</strong><br />
                        <li><strong>Annual Income:</strong> Input your annual income to assess coverage needs.</li>
                        <li><strong>City:</strong> Select your city to adjust coverage based on living expenses.</li>
                        <li >
                          
                          <strong>Occupation:</strong>
                          <li style={{ marginLeft: 20 }}><strong>For Men:</strong> Choose between self-employed, business, or salaried.</li>
                          <li style={{ marginLeft: 20 }}><strong>For Women:</strong> Select between self-employed, business, salaried, or housewife.</li>

                        </li>
                        <li><strong>Education:</strong> Indicate your education level for risk assessment.</li><br />
                        <strong>Step 3: Lifestyle Factors</strong><br />
                        <li><strong>Tobacco/Nicotine Intake:</strong> Specify if you consume tobacco or nicotine.</li>
                        <li><strong>Liabilities:</strong> Mention any financial responsibilities or debts.</li>
                        <li><strong>Future Goals:</strong> Share any future financial goals to factor into coverage needs.</li><br />
                        <strong>How Factors Impact the Results:</strong>
                        <li><strong>Age & Health:</strong> Younger and healthier individuals often qualify for lower premiums.</li>
                        <li>
                          <strong>Income & Occupation:</strong> Higher income might require greater coverage. Different
                          occupations may influence risk assessments.
                        </li>
                        <li><strong>Tobacco/Nicotine:</strong> Users who consume tobacco or nicotine might face higher
                          premiums due to health risks.</li>
                        <li><strong>Liabilities & Future Goals:</strong> Financial responsibilities and future objectives impact
                          coverage needs.</li><br />
                        <strong>Using the Calculator:</strong><br />
                        <span><strong>Fill in Your Details:</strong> Enter accurate personal and financial information and
                         be honest about habits like tobacco or nicotine
                          intake.<br/>
                          <strong>Review and Analyze:</strong> Check the results to understand coverage estimates.<br/>
                          <strong>Assess Suitability:</strong> Evaluate if the coverage aligns with your financial needs and
                          goals.<br />
                          <strong>Adjust if Necessary:</strong> Modify details to see how different factors affect coverage.</span>
                      </div>
                      {/* <div className="pb-5"></div> */}
                    </div>
                    {/* <div className="pb-5"></div> */}
                  </div>
                </div>
              </div>
              <div className="pb-5"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
